import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  getDoc,
  arrayRemove,
  addDoc,
  serverTimestamp,
  updateDoc,
  increment,
  where,
  deleteDoc,
} from "firebase/firestore";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { differenceInCalendarDays, format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCheck,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import MyRentalsMobile from "../components/MyRentalsMobile";
import formatCurrency from "../utils/formatCurrency";
import notFound from "../assets/notFound.png";
import { FaX } from "react-icons/fa6";

const MyRentals = () => {
  const [rentals, setRentals] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [itemLenderDetails, setItemLenderDetails] = useState({});
  const [hoveredRentalId, setHoveredRentalId] = useState(null);
  const [selectedRental, setSelectedRental] = useState({});
  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  const navigate = useNavigate();

  const fetchListingDetails = async (listingId) => {
    const docRef = doc(db, "listings", listingId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : { title: "Listing not found" };
  };

  const fetchItemLenderDetails = async (itemLenderUid) => {
    const docRef = doc(db, "users", itemLenderUid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setItemLenderDetails({ id: docSnap.id, ...docSnap.data() });
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      return null;
    }
  };

  useEffect(() => {
    const fetchRentals = async () => {
      if (!authUser) return;

      const rentalsRef = collection(db, "users", authUser.uid, "rentals");
      const q = query(rentalsRef, orderBy("createdAt", "desc"));
      try {
        const querySnapshot = await getDocs(q);
        const rentalsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const rentalData = doc.data();
            const listingDetails = await fetchListingDetails(
              rentalData.listingId
            );
            const itemLenderDetails = await fetchItemLenderDetails(
              listingDetails.lenderUid
            );

            return {
              ...rentalData,
              id: doc.id,
              listingDetails,
              lenderUsername: itemLenderDetails?.username || "Unknown",
              formattedStartDate: firestoreTimestampToDate(
                rentalData.startDate
              ).toLocaleDateString(),
              formattedEndDate: firestoreTimestampToDate(
                rentalData.endDate
              ).toLocaleDateString(),
            };
          })
        );

        setRentals(rentalsData);
      } catch (error) {
        console.error("Failed to fetch rentals:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchRentals();
  }, [authUser]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleShowCancelRental = (rental) => {
    setShowCancellationModal(true);
    setSelectedRental(rental);
  };

  const processUserRefund = async () => {
    // Calculate the days until the rental start date
    const startDate = firestoreTimestampToDate(selectedRental.startDate);
    const today = new Date();
    const daysUntilRental = differenceInCalendarDays(startDate, today);

    // Determine the cancellation fee percentage based on days until rental
    let cancellationFeePercentage = 0;
    if (daysUntilRental >= 7) {
      cancellationFeePercentage = 0;
    } else if (daysUntilRental > 1 && daysUntilRental < 7) {
      cancellationFeePercentage = 10;
    } else if (daysUntilRental <= 1) {
      cancellationFeePercentage = 20;
    }

    const totalCostCents = selectedRental?.totalCostCents || 0;
    // Divide by 100 to convert percentage to decimal
    const cancellationFeeAmountCents = Math.round(
      (totalCostCents * cancellationFeePercentage) / 100
    );

    // Adjust the refund amount by subtracting the cancellation fee
    const refundAmountCents =
      selectedRental.rentalCostCents - cancellationFeeAmountCents;

    // Ensure the refund amount is not negative
    const finalRefundAmountCents =
      refundAmountCents > 0 ? refundAmountCents : 0;

    // Prepare the body with the adjusted refund amount
    const body = JSON.stringify({
      payment_intent: selectedRental.paymentIntent.id,
      amount: finalRefundAmountCents,
    });

    try {
      const response = await fetch(
        `https://createstriperefund-createstriperefund-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Refund processed:", data);
    } catch (error) {
      console.error("Error processing refund:", error.message);
    }
  };

  const sendCancellationEmailToLender = async () => {
    // Calculate the days until the rental start date
    const startDate = firestoreTimestampToDate(selectedRental.startDate);
    const today = new Date();
    const daysUntilRental = differenceInCalendarDays(startDate, today);

    // Determine the cancellation fee percentage based on days until rental
    let cancellationFeePercentage = 0;
    if (daysUntilRental >= 7) {
      cancellationFeePercentage = 0;
    } else if (daysUntilRental > 1 && daysUntilRental < 7) {
      cancellationFeePercentage = 10;
    } else if (daysUntilRental <= 1) {
      cancellationFeePercentage = 20;
    }

    // Calculate the cancellation fee amount
    const totalCostCents = selectedRental?.totalCostCents || 0;
    // Divide by 100 to convert percentage to decimal
    const cancellationFeeAmountCents =
      (totalCostCents * cancellationFeePercentage) / 100;

    // Prepare the request body with additional cancellation fee information
    const body = JSON.stringify({
      firstName: itemLenderDetails.firstName,
      email: itemLenderDetails.email,
      listingTitle: selectedRental.listingDetails.title,
      renterUsername: userDoc.username,
      reason: inputValue,
      cancellationFeePercentage,
      cancellationFeeAmountCents,
    });

    try {
      const response = await fetch(
        `https://cancelbookedrentalemail-sendcancelbookedrentalema-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Cancellation email sent.");
      setIsCancelling(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteRentalRequest = async () => {
    const body = JSON.stringify({
      rentalRequestId: selectedRental.id,
    });
    try {
      const response = await fetch(
        `https://deleterentalrequest-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Rental availability request deleted.");
      // toast.success("The rental availability request has been deleted.", {
      //   autoClose: 3000,
      // });
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong deleting the rental request.", {
        autoClose: 3000,
      });
    }
  };

  const deleteRentalForRenterAndReduceRentalCount = async () => {
    const rentalRef = doc(
      db,
      "users",
      authUser.uid,
      "rentals",
      selectedRental.id
    );
    await deleteDoc(rentalRef);

    const userRef = doc(db, "users", authUser.uid);
    await updateDoc(userRef, {
      rentalCount: increment(-1),
    });
    console.log("Deleted rental for renter and reduced rental count.");
  };

  const deleteBookingForLenderAndReduceBookingCount = async () => {
    let bookingId;

    const bookingRef = doc(
      db,
      "users",
      authUser.uid,
      "rentals",
      selectedRental.id
    );
    const bookingDoc = await getDoc(bookingRef);
    if (bookingDoc.exists) {
      bookingId = bookingDoc.data().bookingId;
    }

    const q = query(
      collection(db, "users", selectedRental.lenderUid, "bookings"),
      where("bookingId", "==", bookingId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (bookingDoc) => {
      const rentalRef = doc(
        db,
        "users",
        selectedRental.lenderUid,
        "bookings",
        bookingDoc.id
      );

      await deleteDoc(rentalRef);

      const lenderRef = doc(db, "users", selectedRental.lenderUid);
      await updateDoc(lenderRef, {
        bookingCount: increment(-1),
      });

      console.log(`Deleted booking document with ID: ${bookingDoc.id}`);
    });
  };

  const createNewCancellationDocument = async () => {
    const cancellationData = {
      userUid: authUser.uid,
      listingId: selectedRental.listingId,
      cancellationReason: inputValue,
      cancellationDate: serverTimestamp(),
      bookingStartDate: selectedRental.startDate,
      bookingEndDate: selectedRental.endDate,
      cancellationType: "renter-cancellation",
    };

    try {
      await addDoc(collection(db, "cancellations"), cancellationData);
      console.log("Created new cancellation document.");
    } catch (error) {
      console.error("Error processing cancellation:", error);
      toast.error("Failed to process cancellation.");
    }
  };

  const deleteBookingFromListingDocument = async () => {
    try {
      const listingRef = doc(db, "listings", selectedRental.listingId);

      const listingDoc = await getDoc(listingRef);

      if (!listingDoc.exists()) {
        console.error("Listing not found");
        return;
      }

      const { bookings } = listingDoc.data();

      // Find the booking to be removed
      const bookingToRemove = bookings.find(
        (booking) => booking.bookingId === selectedRental.bookingId
      );

      if (!bookingToRemove) {
        console.error("Booking not found in listing");
        return;
      }

      // Remove the booking from the bookings array
      await updateDoc(listingRef, {
        bookings: arrayRemove(bookingToRemove),
      });

      console.log("Booking successfully removed from listing document.");
    } catch (error) {
      console.error("Error removing booking from listing document:", error);
    }
  };

  const incrementCancellationCountForRenter = async () => {
    const userRef = doc(db, "users", authUser.uid);
    await updateDoc(userRef, {
      cancellationCount: increment(1),
    });
    console.log("incremented user cancellation count");
  };

  const removeRentalFromState = () => {
    setRentals((prevRentals) =>
      prevRentals.filter((rental) => rental.id !== selectedRental.id)
    );
  };

  const handleCancelRental = async () => {
    setIsCancelling(true);

    try {
      await processUserRefund();
      await deleteRentalRequest();
      await deleteBookingFromListingDocument();
      await deleteBookingForLenderAndReduceBookingCount();
      await deleteRentalForRenterAndReduceRentalCount();
      await createNewCancellationDocument();
      await incrementCancellationCountForRenter();
      await sendCancellationEmailToLender();

      toast.success("Rental has been cancelled successfully.", {
        autoClose: 3000,
      });

      removeRentalFromState();
    } catch (error) {
      console.error("Failed to cancel rental:", error);
      toast.error("Failed to cancel rental. Please try again.", {
        autoClose: 3000,
      });
    } finally {
      setShowCancellationModal(false);
      setInputValue("");
      setIsCancelling(false);
    }
  };

  if (isFetching) {
    return <Spinner />;
  }

  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "24px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div className="container mx-auto">
      {/* Modal */}
      {showCancellationModal && selectedRental && (
        <Modal
          isOpen={showCancellationModal}
          onRequestClose={() => setShowCancellationModal(false)}
          contentLabel="Cancellation Modal"
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="">
            {/* Ensure selectedRental.startDate exists */}
            {selectedRental.startDate ? (
              <>
                {/* Perform date calculations using your helper function */}
                {(() => {
                  const startDate = firestoreTimestampToDate(
                    selectedRental.startDate
                  );
                  const today = new Date();
                  const daysUntilRental = differenceInCalendarDays(
                    startDate,
                    today
                  );

                  // Determine cancellation fee percentage
                  let cancellationFeePercentage = 0;
                  if (daysUntilRental >= 7) {
                    cancellationFeePercentage = 0;
                  } else if (daysUntilRental > 1 && daysUntilRental < 7) {
                    cancellationFeePercentage = 10;
                  } else if (daysUntilRental <= 1) {
                    cancellationFeePercentage = 20;
                  }

                  // Calculate cancellation fee amount
                  const cancellationFee =
                    (selectedRental.totalCostCents *
                      cancellationFeePercentage) /
                      100 || 0;

                  // Format start date
                  const formattedStartDate = format(startDate, "MMMM do, yyyy");

                  return (
                    <>
                      {/* Modal Heading */}
                      <div className="flex justify-between mb-2">
                        <h2 className="text-lg md:text-xl font-semibold">
                          Cancel Rental
                        </h2>
                        <button onClick={() => setShowCancellationModal(false)}>
                          <FaX />
                        </button>
                      </div>

                      {/* Confirmation Message */}
                      <p className="text-sm md:text-base mb-4">
                        Are you sure you want to cancel this rental?
                      </p>

                      {/* Cancellation Policy Section */}
                      <div className="mb-6">
                        <h3 className="font-semibold text-base md:text-lg mb-2 md:mb-2">
                          Cancellation Policy
                        </h3>
                        <p className="text-sm mb-2">
                          Your rental is scheduled to start on{" "}
                          <span className="font-medium">
                            {formattedStartDate}
                          </span>
                          , which is in{" "}
                          <span className="font-medium">{daysUntilRental}</span>{" "}
                          day(s).
                        </p>
                        <p className="text-sm mb-4">
                          Based on our policy, cancelling now will result in a{" "}
                          <span className="font-semibold">
                            {cancellationFeePercentage}% cancellation fee
                          </span>
                          {cancellationFeePercentage > 0 && (
                            <>
                              , amounting to{" "}
                              <span className="font-semibold">
                                €{formatCurrency(cancellationFee)}
                              </span>
                              .
                            </>
                          )}
                        </p>
                        <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
                          <p className="text-sm font-bold mb-2">
                            Cancellation Fees:
                          </p>
                          <ul className="list-disc list-inside text-sm space-y-1">
                            <li>
                              <strong>
                                7 days or more before rental start:
                              </strong>{" "}
                              No cancellation fee.
                            </li>
                            <li>
                              <strong>
                                Less than 7 days but more than 1 day:
                              </strong>{" "}
                              10% cancellation fee.
                            </li>
                            <li>
                              <strong>
                                1 day or less before rental start:
                              </strong>{" "}
                              20% cancellation fee.
                            </li>
                          </ul>
                        </div>
                        {/* <p className="text-sm mb-2">
                          <span className="font-medium">Note:</span> The service
                          fee of{" "}
                          <span className="font-semibold">
                            €
                            {selectedRental.serviceFee &&
                              selectedRental.serviceFee.toFixed(2)}
                          </span>{" "}
                          is non-refundable.
                        </p> */}
                        <p className="text-xs md:text-sm text-gray-500">
                          For more details, please read our{" "}
                          <span
                            onClick={() => navigate("/cancellation-policy")}
                            className="text-blue-600 hover:underline cursor-pointer font-medium"
                          >
                            Cancellation Policy
                          </span>
                          .
                        </p>
                      </div>

                      {/* Reason for Cancellation */}
                      <div className="flex flex-col mb-6">
                        <label className="text-sm font-semibold mb-1">
                          Reason for Cancellation{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <span className="text-xs text-gray-500 mb-2">
                          This will be sent to the lender and logged in our
                          system.
                        </span>
                        <textarea
                          className="text-sm p-2 border rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                          placeholder="Please provide your reason..."
                          value={inputValue}
                          onChange={handleInputChange}
                          rows={4}
                        />
                      </div>

                      {/* Action Buttons */}
                      <div className="flex gap-4 justify-end">
                        <button
                          className="text-sm md:text-base px-4 btn-cancel"
                          onClick={() => setShowCancellationModal(false)}
                        >
                          {/* <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="mr-2"
                          /> */}
                          Keep Rental
                        </button>
                        <button
                          className={`text-sm md:text-base px-4 py-2 text-white font-semibold rounded-md focus:outline-none focus:ring-2 ${
                            inputValue.length < 6 || isCancelling
                              ? "bg-gray-400 cursor-not-allowed"
                              : "btn-continue"
                          }`}
                          onClick={handleCancelRental}
                          disabled={isCancelling || inputValue.length < 6}
                        >
                          {isCancelling ? (
                            <BeatLoader color="white" size={10} />
                          ) : (
                            <>
                              {/* <FontAwesomeIcon
                                icon={faCheck}
                                className="mr-2"
                              /> */}
                              Confirm Cancellation
                            </>
                          )}
                        </button>
                      </div>
                    </>
                  );
                })()}
              </>
            ) : (
              <p>Loading rental details...</p>
            )}
          </div>
        </Modal>
      )}

      <div className="flex w-full justify-center">
        <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
          My Rentals
        </h1>
      </div>

      {/* Mobile View - Render the mobile component */}
      <div className="block lg:hidden">
        <MyRentalsMobile
          rentals={rentals}
          handleShowCancelRental={handleShowCancelRental}
        />
      </div>

      <div className="hidden lg:block w-full">
        {rentals.length > 0 ? (
          <table className="min-w-full table-auto text-center bg-white shadow-lg rounded-lg">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="px-6 py-3 font-semibold">Item</th>
                <th className="px-6 py-3 font-semibold">Lender</th>
                <th className="px-6 py-3 font-semibold">Days</th>
                <th className="px-6 py-3 font-semibold">Rate</th>
                <th className="px-6 py-3 font-semibold">Cost</th>
                <th className="px-6 py-3 font-semibold">Start Date</th>
                <th className="px-6 py-3 font-semibold">End Date</th>
                <th className="px-20 py-3 font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {rentals.map((rental, index) => (
                <tr
                  key={rental.id}
                  className={`bg-white border-b ${
                    index % 2 === 0 ? "bg-gray-100" : ""
                  } hover:bg-gray-100`}
                >
                  <td
                    className="border px-6 py-4 hover:cursor-pointer hover:font-semibold"
                    onClick={() => navigate(`/listings/${rental.listingId}`)}
                  >
                    {rental.listingDetails.title}
                  </td>
                  <td
                    className="border px-4 py-2 hover:cursor-pointer hover:font-semibold"
                    onClick={() =>
                      navigate(`/users/${rental.listingDetails.lenderUid}`)
                    }
                  >
                    {rental.lenderUsername}
                  </td>
                  <td className="border px-6 py-4">{rental.numberOfDays}</td>
                  <td className="border px-6 py-4">
                    €{formatCurrency(rental.rateCents)}
                  </td>
                  <td className="border px-6 py-4 relative">
                    €{formatCurrency(rental.totalCostCents)}
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ml-2 text-gray-600 text-lg cursor-pointer"
                      onMouseEnter={() => setHoveredRentalId(rental.id)}
                      onMouseLeave={() => setHoveredRentalId(null)}
                    />
                    {hoveredRentalId === rental.id && (
                      <div className="absolute left-0 top-10 bg-gray-800 text-white p-2 rounded-md shadow-lg z-50 w-64 text-left">
                        <div>
                          <p className="font-semibold">Cost Breakdown</p>
                          <p>
                            <strong>Rental Total:</strong> €
                            {formatCurrency(rental.rentalCostCents)}
                          </p>
                          <p>
                            <strong>Service Fee:</strong> €
                            {formatCurrency(rental.serviceFeeCents)}
                          </p>
                          {/* <p className="text-sm">
                            <strong>- Service Fee:</strong> €
                            {formatCurrency(rental.serviceFeeCents * 0.77)}
                          </p>
                          <p className="text-sm">
                            <strong>- VAT (23%):</strong> €
                            {formatCurrency(rental.serviceFeeCents * 0.23)}
                          </p> */}
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="border px-6 py-4">
                    {rental.formattedStartDate}
                  </td>
                  <td className="border px-6 py-4">
                    {rental.formattedEndDate}
                  </td>
                  <td className="border px-6 py-4">
                    {firestoreTimestampToDate(rental.startDate) > new Date() ? (
                      <button
                        onClick={() => handleShowCancelRental(rental)}
                        className="btn-cancel px-2 py-1"
                      >
                        <FontAwesomeIcon icon={faCancel} /> Cancel Rental
                      </button>
                    ) : (
                      <div className="p-1 bg-gray-100 rounded-lg">N/A</div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex flex-col justify-center align-center items-center">
            <img src={notFound} alt="not-found" className="w-48" />
            <p className="font-semibold">No rentals found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyRentals;
