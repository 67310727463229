import React, { createContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

/**
 * The AuthContext holds minimal authentication state:
 *   - authUser: the raw Firebase Auth user object
 *   - authLoading: whether we’re still initializing or checking the current user
 */
export const AuthContext = createContext({
  authUser: null, // Will store the raw Firebase user (uid, email, etc.)
  authLoading: true,
});

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    // Listen for changes to Firebase Auth user
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthUser(user);
      setAuthLoading(false);
    });

    return () => {
      // Cleanup subscription on unmount
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ authUser, authLoading }}>
      {/* Only render children once we know if user is signed in or not */}
      {!authLoading && children}
    </AuthContext.Provider>
  );
};
