import React, { useState, useEffect } from "react";
import ListingCard from "./ListingCard";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import { db } from "../firebase.config";
import {
  collection,
  query,
  where,
  doc,
  orderBy,
  getDoc,
  limit,
  getDocs,
} from "firebase/firestore";

const LatestListings = () => {
  const [listings, setListings] = useState([]);
  const [title, setTitle] = useState("Latest Listings Added to the Site");

  const { authUser } = useAuthUser();

  useEffect(() => {
    const fetchListings = async () => {
      try {
        let listingsQuery;
        let userCounty = null;

        if (authUser) {
          // Fetch the user's address components
          const userRef = doc(db, "users", authUser.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            userCounty = userData.addressComponents?.county || null;

            if (userCounty) {
              setTitle(`Latest Listings in ${userCounty}`);
            } else {
              setTitle("Latest Listings Near You");
            }
          } else {
            console.log("No such document!");
          }
        }

        if (userCounty) {
          // Query listings in the user's county
          listingsQuery = query(
            collection(db, "listings"),
            where("addressComponents.county", "==", userCounty),
            where("isActive", "==", true),
            orderBy("createdAt", "desc"),
            limit(8)
          );
        } else {
          // User not logged in or county not found, fetch latest listings
          listingsQuery = query(
            collection(db, "listings"),
            where("isActive", "==", true),
            orderBy("createdAt", "desc"),
            limit(8)
          );
        }

        const listingDocs = await getDocs(listingsQuery);
        const fetchedListings = listingDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setListings(fetchedListings);
      } catch (error) {
        console.error("Error fetching listings:", error);
      }
    };

    fetchListings();
  }, [authUser]);

  // Return null if no listings are found
  if (listings.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center items-center align-center py-5 bg-gray-50">
      <h1 className="text-gray-600 text-lg md:text-2xl font-semibold mb-5 md:mb-8">
        {title}
      </h1>
      <div className="p-2 grid grid-cols-2 lg:grid-cols-4 gap-3 md:gap-10 mb-5">
        {listings.map((listingDetails) => (
          <ListingCard
            key={listingDetails.id}
            listingDetails={listingDetails}
          />
        ))}
      </div>
    </div>
  );
};

export default LatestListings;
