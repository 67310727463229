import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import formatCurrency from "../../utils/formatCurrency";

const ListingDetails = ({ listingDetails }) => {
  const [showAdDescription, setShowAdDescription] = useState(true);

  const {
    title,
    description,
    dailyRateCents,
    // threePlusDayRate,
    // sevenPlusDayRate,
    threeDayCostCents,
    sevenDayCostCents,
    category,
    subcategory,
    depositCents,
  } = listingDetails;

  return (
    <div className="bg-white shadow-md rounded-lg p-2 md:p-6 mb-5">
      <h1 className="text-xl md:text-2xl font-semibold text-gray-700 mb-2">
        {title}
      </h1>
      <div className="flex flex-col gap-4">
        <p className="text-sm md:text-base text-gray-700">
          Daily Rate:{" "}
          <span className="font-semibold">
            €{formatCurrency(dailyRateCents)} per day
          </span>
        </p>
        {/* {threePlusDayRate ? (
          <p className="text-base text-gray-700">
            3+ Day Rate:{" "}
            <span className="font-semibold">
              €{threePlusDayRate.toFixed(2)} per day
            </span>
          </p>
        ) : null} */}
        {threeDayCostCents ? (
          <p className="text-sm md:text-base text-gray-700">
            Price for 3 days:{" "}
            <span className="font-semibold">
              €{formatCurrency(threeDayCostCents)}
            </span>
          </p>
        ) : null}
        {sevenDayCostCents ? (
          <p className="text-sm md:text-base text-gray-700">
            Price for 7 days:{" "}
            <span className="font-semibold">
              €{formatCurrency(sevenDayCostCents)}
            </span>
          </p>
        ) : null}

        <p className="text-sm md:text-base text-gray-600">
          Category: <span className="font-semibold">{category}</span>
        </p>
        {subcategory && (
          <p className="text-sm md:text-base text-gray-600">
            Subcategory: <span className="font-semibold">{subcategory}</span>
          </p>
        )}
        {/* Description Toggle */}
        <div className="border-t border-gray-200 pt-4">
          <button
            className="flex items-center space-x-2 text-gray-800 hover:text-gray-500"
            onClick={() => setShowAdDescription(!showAdDescription)}
          >
            {/* <FontAwesomeIcon icon={faBook} className="text-lg" /> */}
            <span className="font-semibold">Item Details</span>
            <FontAwesomeIcon
              icon={showAdDescription ? faChevronUp : faChevronDown}
            />
          </button>
          {showAdDescription && (
            <p className="text-sm md:text-base mt-2 text-gray-600 whitespace-pre-line">
              {description}
            </p>
          )}
        </div>

        {/* Deposit */}
        {depositCents && depositCents > 0 ? (
          <div>
            <h2 className="font-semibold">
              Deposit Required:{" "}
              <span className="font-semibold">
                €{formatCurrency(depositCents)}
              </span>
            </h2>
            <p className="text-xs text-gray-600 md:text-sm">
              Some lenders may require a security deposit for their rentals.
              This deposit enhances the trust and security between the renter
              and the lender, ensuring a smooth and secure transaction. By
              requiring a deposit, lenders can safeguard their valuable items,
              and renters can be assured of the lender's commitment to the
              rental agreement. The deposit will be collected by the lender at
              the start of the rental period and will be fully refunded at the
              end of the rental, provided the item is returned in its original
              condition.
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ListingDetails;
