import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { BeatLoader } from "react-spinners";

const LoginFormModal = ({ onClose }) => {
  const { signIn } = useAuth(); // hypothetical signIn function from your auth context
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { email, password } = formData;

  const closeLoginModal = () => setIsLoginModalOpen(false);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const signUserIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredential.user) {
        setIsLoading(false);
        toast.success("Logged in successfully!", {
          autoClose: 1000,
        });
        closeLoginModal();
      }
    } catch (error) {
      toast.error("Incorrect email or password.");
      setIsLoading(false);
    }
  };

  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "24px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div>
      <Modal
        isOpen={isLoginModalOpen}
        onRequestClose={closeLoginModal}
        contentLabel="Login Modal"
        ariaHideApp={false}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <h2 className="text-lg font-semibold mb-4 text-teal-700">
          Sign In to Submit Review
        </h2>
        {/* <p className="text-xs md:text-sm text-gray-600">
          To submit reviews, we need our lenders/renters logged in to ensure the
          reviews are authorised and trusted.
        </p> */}
        <form className="m-0" onSubmit={signUserIn}>
          <div className="mb-3">
            <p className="text-xs md:text-sm text-gray-600 mb-1 font-semibold">
              Email
            </p>
            <input
              type="email"
              className="border p-2 w-full outline-none focus:border-teal-600"
              value={email}
              placeholder="Enter email"
              id="email"
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <p className="text-xs md:text-sm text-gray-600 mb-1 font-semibold">
              Password
            </p>
            <input
              type="password"
              id="password"
              placeholder="Enter password"
              className="border p-2 w-full outline-none focus:border-teal-600"
              value={password}
              onChange={onChange}
            />
          </div>
          <div className="flex justify-end">
            <button className="btn-primary px-4 py-2 mt-1" type="submit">
              {isLoading ? <BeatLoader color="white" /> : "Sign In"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default LoginFormModal;
