import { getAuth } from "firebase/auth";

export async function checkEmailVerified() {
  const auth = getAuth();
  if (!auth.currentUser) return false;
  try {
    await auth.currentUser.reload();

    return auth.currentUser.emailVerified;
  } catch (error) {
    console.error("Error reloading user data:", error);
    return false;
  }
}

export async function checkIdApproved() {
  const auth = getAuth();
  if (!auth.currentUser) return false;
  try {
    await auth.currentUser.reload();
    const veriffData = auth.currentUser?.veriff;
    if (!veriffData || !veriffData.decision) return false;
    return veriffData.decision === "approved";
  } catch (error) {
    console.error("Error reloading user data:", error);
    return false;
  }
}
