import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import {
  FaEuroSign,
  FaRegCalendarCheck,
  FaRegHeart,
  FaRegCircleUser,
  FaList,
  FaPlus,
  FaArrowRightToBracket,
  FaMessage,
  FaMoneyBillTransfer,
  FaMagnifyingGlass,
} from "react-icons/fa6";
import "../css/Navbar.css";
import { db, auth } from "../firebase.config";
import {
  collection,
  query,
  where,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoWithText from "../assets/logoWithText.png";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  const navigate = useNavigate();

  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  let profilePictureURL = userDoc?.profilePictureURL || "";

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUserIsLoggedIn(!!user);
    });
  }, []);

  useEffect(() => {
    if (authUser) {
      const rentalRequestsRef = collection(db, "rental-requests");
      const q = query(
        rentalRequestsRef,
        where("unreadBy", "array-contains", authUser.uid),
        limit(1)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        setHasUnreadMessages(!snapshot.empty);
      });

      return () => unsubscribe();
    }
  }, [authUser]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUserIsLoggedIn(!!user);
    });
  }, [auth]);

  const ref = useRef(null);
  const dropdownRef = useRef(null);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setUserIsLoggedIn(false);
      setIsAccountDropdownOpen(false);
      setIsOpen(!isOpen);
      navigate("/");
      toast.success("Signed out successfully.", { autoClose: 2000 });
    });
  };

  // Event handler to close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (
      isAccountDropdownOpen &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsAccountDropdownOpen(false);
    }
  };

  useEffect(() => {
    // Listen for both mousedown and touchstart events
    const events = ["mousedown", "touchstart"];
    events.forEach((event) => {
      document.addEventListener(event, handleClickOutside);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleClickOutside);
      });
    };
  }, [isAccountDropdownOpen]);

  return (
    <div>
      <nav className="bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between md:justify-center h-16">
            <div className="flex">
              {/* <img src={logo} className="w-16" /> */}
              <div
                className="flex-shrink-0 absolute top-3 left-2 sm:left-5 md:top-3"
                onClick={() => navigate("/")}
              >
                {/* <p className="text-white tracking-wide font-semibold">
                  KitShare
                </p> */}
                <img
                  src={logoWithText}
                  alt="kitshare-logo"
                  className="w-32 md:w-36"
                />
              </div>

              <div className="hidden md:block">
                <div className="flex justify-center align-center items-center gap-3">
                  {" "}
                  <NavLink
                    to="/"
                    onClick={() => setIsAccountDropdownOpen(false)}
                    className="text-white no-underline hover:bg-secondary hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                  >
                    Home
                  </NavLink>
                  {/* <SearchButtonNav /> */}
                  <NavLink
                    to="/search-results"
                    onClick={() => setIsAccountDropdownOpen(false)}
                    className="flex gap-2 justify-center items-center align-center text-white no-underline hover:bg-secondary hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                  >
                    <FaMagnifyingGlass />
                    <p>Search</p>
                  </NavLink>
                  {userIsLoggedIn ? (
                    <div className="flex justify-center items-center align-center hover:cursor-pointer gap-3">
                      <NavLink
                        to="/new-listing"
                        onClick={() => setIsAccountDropdownOpen(false)}
                        className="no-underline text-white hover:bg-secondary hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                      >
                        <div className="flex justify-start align-center items-center">
                          {/* <HiOutlinePlusCircle className="inline mr-1" /> */}
                          <span>List an Item</span>
                        </div>
                      </NavLink>

                      <NavLink
                        to="/inbox"
                        className="no-underline text-white hover:bg-secondary hover:text-white px-3 py-2 rounded-md text-sm lg:text-base"
                        onClick={() => setIsAccountDropdownOpen(false)}
                      >
                        {hasUnreadMessages ? (
                          <span className="text-red-500 font-semibold">
                            Inbox (1)
                          </span>
                        ) : (
                          <span className="">Inbox</span>
                        )}
                      </NavLink>

                      {/* Account Dropdown */}
                      <div ref={dropdownRef} className="relative">
                        <button
                          onClick={() =>
                            setIsAccountDropdownOpen(!isAccountDropdownOpen)
                          }
                          className="text-white hover:bg-secondary hover:text-white px-3 py-2 rounded-md text-base font-medium"
                        >
                          {profilePictureURL ? (
                            <div className="flex justify-center align-center items-center gap-2">
                              <img
                                src={profilePictureURL}
                                className="w-8 h-8 rounded-full"
                                alt="profilePicture"
                              />
                              <p>My Account</p>
                            </div>
                          ) : (
                            <div className="flex align-center items-center gap-2">
                              <FaRegCircleUser />
                              <span>My Account</span>
                            </div>
                          )}
                        </button>
                        {isAccountDropdownOpen && (
                          <div className="absolute right-0 mt-1 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                            <NavLink
                              to="/my-profile"
                              className="block px-4 py-2  text-sm capitalize text-gray-700 hover:font-bold hover:bg-gray-100"
                              onClick={() => setIsAccountDropdownOpen(false)}
                            >
                              <div className="flex justify-start align-center items-center">
                                <FaRegCircleUser className="inline mr-1" />
                                <span>My Profile</span>
                              </div>
                            </NavLink>
                            <NavLink
                              to="/my-listings"
                              className="block px-4 py-2  text-sm capitalize text-gray-700 hover:font-bold hover:bg-gray-100"
                              onClick={() => setIsAccountDropdownOpen(false)}
                            >
                              <div className="flex justify-start align-center items-center">
                                <FaList className="inline mr-1" />
                                <span>My Listings</span>
                              </div>
                            </NavLink>
                            <NavLink
                              to="/my-bookings"
                              className="block px-4 py-2  text-sm capitalize text-gray-700 hover:font-bold hover:bg-gray-100"
                              onClick={() => setIsAccountDropdownOpen(false)}
                            >
                              <div className="flex justify-start align-center items-center">
                                <FaEuroSign className="inline mr-1" />
                                <span>Bookings</span>
                              </div>
                            </NavLink>
                            <NavLink
                              to="/my-rentals"
                              className="block px-4 py-2  text-sm capitalize text-gray-700 hover:font-bold hover:bg-gray-100"
                              onClick={() => setIsAccountDropdownOpen(false)}
                            >
                              <div className="flex justify-start align-center items-center">
                                <FaRegCalendarCheck className="inline mr-1" />
                                <span>Rentals</span>
                              </div>
                            </NavLink>
                            <NavLink
                              to="/favourites"
                              className="block px-4 py-2  text-sm capitalize text-gray-700 hover:font-bold hover:bg-gray-100"
                              onClick={() => setIsAccountDropdownOpen(false)}
                            >
                              <div className="flex justify-start align-center items-center">
                                <FaRegHeart className="inline mr-1" />
                                <span>Favourites</span>
                              </div>
                            </NavLink>

                            <NavLink
                              to="/lenders-hub"
                              className="block px-4 py-2  text-sm capitalize text-gray-700 hover:font-bold hover:bg-gray-100 "
                              onClick={() => setIsAccountDropdownOpen(false)}
                            >
                              {/* <img
                                src={stripeConnect}
                                alt="stripe"
                                className="w-4 inline mr-1"
                              /> */}

                              <div className="flex justify-start align-center items-center">
                                <FaMoneyBillTransfer className="inline mr-1" />
                                <span> Lenders Hub</span>
                              </div>
                            </NavLink>
                            <button
                              onClick={handleSignOut}
                              className="border-t-2 pt-3 w-full text-left block px-4 pb-1 text-sm capitalize text-red-600  hover:font-bold"
                            >
                              <div className="flex justify-start align-center items-center">
                                <FaArrowRightToBracket className="inline mr-1" />
                                <span>Log Out</span>
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-3 ">
                      <NavLink
                        to="/new-listing"
                        className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                        onClick={() => setIsOpen(false)}
                      >
                        <div className="flex justify-start align-center items-center">
                          <FaPlus className="inline mr-1" />
                          <span>List an Item</span>
                        </div>
                      </NavLink>
                      <NavLink
                        to="/sign-in"
                        className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
                        onClick={() => setIsAccountDropdownOpen(false)}
                      >
                        Sign In
                      </NavLink>
                      <NavLink
                        to="/sign-up"
                        onClick={() => setIsAccountDropdownOpen(false)}
                        className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
                      >
                        Sign Up
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-secondary inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-white hover:bg-secondary shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="md:hidden" id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLink
                to="/"
                className="no-underline hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                <FontAwesomeIcon icon={faHome} className="" /> Home
              </NavLink>

              {/* <SearchButtonNav /> */}

              <NavLink
                to="/search-results"
                onClick={() => setIsOpen(false)}
                className="no-underline hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                <FontAwesomeIcon icon={faSearch} className="" /> Search
              </NavLink>

              {userIsLoggedIn ? (
                <>
                  <NavLink
                    to="/inbox"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    {hasUnreadMessages ? (
                      <div className="flex justify-start align-center items-center text-red-500 font-semibold ">
                        <FaMessage className="inline mr-1" />
                        <span>Inbox (1)</span>
                      </div>
                    ) : (
                      <div className="flex justify-start align-center items-center font-semibold ">
                        <FaMessage className="inline mr-1" />
                        <span>Inbox</span>
                      </div>
                    )}
                  </NavLink>
                  <NavLink
                    to="/new-listing"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="flex justify-start align-center items-center">
                      <FaPlus className="inline mr-1" />
                      <span>List an Item</span>
                    </div>
                  </NavLink>

                  <NavLink
                    to="/my-profile"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="flex justify-start align-center items-center">
                      <FaRegCircleUser className="inline mr-1" />
                      <span>My Profile</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/my-listings"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="flex justify-start align-center items-center">
                      <FaList className="inline mr-1" />
                      <span>My Listings</span>
                    </div>
                  </NavLink>

                  <NavLink
                    to="/my-bookings"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="flex justify-start align-center items-center">
                      <FaEuroSign className="inline mr-1" />
                      <span>Bookings</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/my-rentals"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="flex justify-start align-center items-center">
                      <FaRegCalendarCheck className="inline mr-1" />
                      <span>Rentals</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/favourites"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="flex justify-start align-center items-center">
                      <FaRegHeart className="inline mr-1" />
                      <span>Favourites</span>
                    </div>
                  </NavLink>

                  <NavLink
                    to="/lenders-hub"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    {/* <img
                      src={stripeConnect}
                      alt="stripe"
                      className="w-4 inline mr-1"
                    /> */}
                    <div className="flex justify-start align-center items-center">
                      <FaMoneyBillTransfer className="inline mr-1" />
                      <span> Lenders Hub</span>
                    </div>
                  </NavLink>
                  <button
                    onClick={handleSignOut}
                    className="no-underline text-red-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-semibold"
                  >
                    <div className="flex justify-start align-center items-center">
                      <FaArrowRightToBracket className="inline mr-1" />
                      <span>Log Out</span>
                    </div>
                  </button>
                </>
              ) : (
                <>
                  <NavLink
                    to="/new-listing"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="flex justify-start align-center items-center">
                      <FaPlus className="inline mr-1" />
                      <span>List an Item</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/sign-in"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Sign In
                  </NavLink>
                  <NavLink
                    to="/sign-up"
                    className="no-underline text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Sign Up
                  </NavLink>
                </>
              )}
            </div>
          </div>
        </Transition>
      </nav>
    </div>
  );
};

export default Navbar;
