import React from "react";
import Footer from "../components/Footer";
import AlgoliaSearch from "../components/AlgoliaSearch";
import HowItWorks from "../components/HowItWorks";
import FAQ from "../components/FAQ";
import LatestListings from "../components/LatestListings";
import BecomeLenderCTA from "../components/BecomeLenderCTA";
import GuaranteePackages from "../components/GuaranteePackages";
import { useAuthUser } from "../hooks/useAuthUser";

const Home = () => {
  const { authUser } = useAuthUser();

  return (
    <>
      <div className="relative h-screen bg-waves flex justify-center bg-gray-100">
        {/* Container to position content */}

        <div className="absolute  top-1/4 md:top-1/6 w-full flex flex-col md:flex-row items-center justify-center max-w-screen-lg md:px-0">
          {/* 2/3 of screen (desktop) */}
          <div className="flex flex-col gap-5 w-full md:w-2/3 items-center md:items-start text-center md:text-left">
            <p className="text-gray-600 text-lg">
              <span className="text-teal-600 font-bold">Rent</span> instead of
              buying
            </p>
            <p className="text-gray-600 text-2xl md:text-5xl">
              Hire <span className="text-teal-600 font-semibold">anything</span>{" "}
              from people in your area
            </p>

            <div className="w-full mt-4">
              <AlgoliaSearch />
            </div>
          </div>
        </div>
      </div>
      <GuaranteePackages />

      <div className="flex justify-center py-2 bg-gray-100">
        <HowItWorks />
      </div>

      <div className="">
        <LatestListings />
      </div>
      <BecomeLenderCTA authUser={authUser} />

      <FAQ />
      <Footer authUser={authUser} />
    </>
  );
};

export default Home;
