import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase.config";
import formatCurrency from "../utils/formatCurrency";

const RentalConfirmed = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [listingImage, setListingImage] = useState(null);

  useEffect(() => {
    if (!state) {
      // Redirect to homepage if accessed without state
      navigate("/");
      return;
    }

    const fetchListingImage = async () => {
      try {
        const listingDocRef = doc(db, "listings", state.listingId);
        const listingDoc = await getDoc(listingDocRef);
        if (listingDoc.exists()) {
          const { imageURLs } = listingDoc.data();
          if (imageURLs && imageURLs.length > 0) {
            setListingImage(imageURLs[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching listing image:", error);
      }
    };

    fetchListingImage();
  }, [state, navigate]);

  if (!state) return null;

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center py-10 px-4">
      <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl md:text-2xl font-semibold text-teal-700 text-center mb-6">
          Rental Confirmed!
        </h2>

        {listingImage && (
          <div className="w-full mb-4 flex justify-center">
            <img
              src={listingImage}
              alt={state.listingTitle}
              className="w-2/3 lg:w-1/2 object-fit rounded-lg shadow-md"
            />
          </div>
        )}

        <h3 className="text-lg md:text-xl font-semibold text-teal-700 text-center mb-4">
          {state.listingTitle}
        </h3>

        <div className="flex flex-col text-center mb-6 gap-2">
          <p className="text-sm md:text-base text-gray-600">
            <strong>Rental Duration:</strong>{" "}
            {state.duration === 1
              ? `${state.duration} day`
              : `${state.duration} days`}{" "}
          </p>
          <p className="text-sm md:text-base text-gray-600">
            <strong>Start Date:</strong> {state.startDate}
          </p>
          <p className="text-sm md:text-base text-gray-600">
            <strong>End Date:</strong> {state.endDate}
          </p>
        </div>

        <div className="bg-gray-100 rounded-lg p-4 text-center">
          <h4 className="text-base md:text-lg font-semibold text-gray-700 mb-2">
            Payment Details
          </h4>
          <p className="text-sm md:text-base text-gray-600">
            <strong>Rental Cost:</strong>{" "}
            {`€${formatCurrency(state.rentalCostCents)}`}
          </p>
          <p className="text-sm md:text-base text-gray-600">
            <strong>Service Fee:</strong>{" "}
            {`€${formatCurrency(state.serviceFeeCents)}`}
          </p>
          <p className="text-base md:text-base font-bold text-gray-600 mt-3">
            <strong>Total Cost:</strong>{" "}
            {`€${formatCurrency(state.totalCostCents)}`}
          </p>
        </div>

        <div className="mt-6 text-center">
          <button
            className="text-sm md:text-base bg-teal-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-teal-700 transition duration-300"
            onClick={() => navigate("/my-rentals")}
          >
            View My Rentals
          </button>
        </div>
      </div>
    </div>
  );
};

export default RentalConfirmed;
