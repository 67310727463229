import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faHashtag,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import { toast } from "react-toastify";

const EnquireModal = ({ isOpen, onClose, listing }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();

  const today = new Date();
  const bookings = listing.bookings || [];

  // Function to get excluded dates based on bookings
  const getExcludedDates = () => {
    const excludedDates = [];
    bookings.forEach((booking) => {
      const start = new Date(firestoreTimestampToDate(booking.startDate));
      const end = new Date(firestoreTimestampToDate(booking.endDate));
      for (
        let day = new Date(start);
        day <= end;
        day.setDate(day.getDate() + 1)
      ) {
        excludedDates.push(new Date(day));
      }
    });
    return excludedDates;
  };

  // Function to check if the selected range overlaps with booked dates
  const isRangeValid = (start, end) => {
    for (const booking of bookings) {
      const bookedStart = new Date(firestoreTimestampToDate(booking.startDate));
      const bookedEnd = new Date(firestoreTimestampToDate(booking.endDate));

      // Check if the selected range overlaps with any booking range
      if (
        (start >= bookedStart && start <= bookedEnd) ||
        (end >= bookedStart && end <= bookedEnd) ||
        (start <= bookedStart && end >= bookedEnd)
      ) {
        return false; // Overlap detected
      }
    }
    return true; // No overlap
  };

  const excludedDates = bookings ? getExcludedDates() : [];

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleContinue = () => {
    if (!startDate || !endDate) {
      toast.error(
        "Please select a valid date range before continuing. If you are selecting one day, you need to select this date twice."
      );
      return;
    }

    if (!isRangeValid(startDate, endDate)) {
      toast.error(
        "The selected date range overlaps with an existing booking. Please choose a different range.",
        { autoClose: 5000 }
      );
      return;
    }

    onClose();
    navigate("/check-availability", {
      state: { startDate, endDate, listing },
    });
  };

  const calculateDaysBetweenDates = () => {
    if (!startDate || !endDate) return 0;
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    // Only set the dates if they do not overlap with existing bookings
    if (start && end && isRangeValid(start, end)) {
      setStartDate(start);
      setEndDate(end);
    } else {
      // If invalid, clear the selection or just set the start date
      if (!isRangeValid(start, end)) {
        toast.error(
          "The selected date range overlaps with an existing booking. Please select a different range."
        );
      }
      setStartDate(start);
      setEndDate(null); // Clear end date to prevent invalid range
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255,255,255,0.8)",
      width: "fit-content", // Adjust width to fit content
      padding: "20px", // Optional: adjust padding as needed
      overflow: "visible", // Ensure content is not clipped
    },
  };

  return (
    <div className="">
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Enquire Modal"
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="relative mb-5">
          <h2 className="font-semibold text-left md:text-lg">
            Select Booking Dates
          </h2>
          <button
            type="button"
            className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
            onClick={onClose}
            aria-label="Close modal"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </div>
        <div className="flex justify-center">
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            minDate={today}
            excludeDates={excludedDates}
            inline
            monthsShown={2}
          />
        </div>
        <div className="flex justify-end gap-3 mt-5">
          <button
            className="px-5 btn-delete text-sm sm:text-base"
            onClick={handleClearDates}
          >
            Clear dates
          </button>
          <button
            className="px-5 btn-continue text-sm sm:text-base"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
        {startDate && endDate && (
          <div className="flex flex-col gap-2 pt-5">
            <div className="flex gap-1 items-center text-sm sm:text-base">
              <FontAwesomeIcon icon={faCalendarCheck} />
              <span className="font-semibold ">Selected duration:</span>{" "}
              {startDate.toDateString()} - {endDate.toDateString()}
            </div>
            <div className="flex gap-1 items-center text-sm sm:text-base">
              <FontAwesomeIcon icon={faHashtag} />
              <span className="font-semibold">Number of days:</span>{" "}
              {calculateDaysBetweenDates()}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default EnquireModal;
