import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const GuaranteePackages = () => {
  // Single piece of state to track if we show/hide ALL scenarios
  const [showAllScenarios, setShowAllScenarios] = useState(false);

  // Toggle function called by clicking ANY info icon
  const toggleAllScenarios = () => {
    setShowAllScenarios((prev) => !prev);
  };

  const packages = [
    {
      name: "Bronze Package",
      percentage: 10,
      coverage: 500,
      medalColor: "#CD7F32",
      scenario: (
        <>
          <p className="text-sm text-gray-700 mt-2">
            For example, if you earn €100 from a rental, selecting the Bronze
            package means 10% (€10) is deducted from your earnings to provide up
            to €500 in coverage. You'd receive €90, and if damage occurs to your
            item, you have up to €500 in coverage.
          </p>
        </>
      ),
    },
    {
      name: "Silver Package",
      percentage: 15,
      coverage: 1200,
      medalColor: "#C0C0C0",
      scenario: (
        <>
          <p className="text-sm text-gray-700 mt-2">
            For example, if you earn €100 from a rental, selecting the Silver
            package means 15% (€15) is deducted, leaving you €85. In return, you
            have coverage up to €1,200 if something goes wrong with your item.
          </p>
        </>
      ),
    },
    {
      name: "Gold Package",
      percentage: 20,
      coverage: 2000,
      medalColor: "#FFD700",
      scenario: (
        <>
          <p className="text-sm text-gray-700 mt-2">
            For example, if you earn €100 from a rental, with the Gold package
            20% (€20) is deducted, leaving you €80. In exchange, you receive up
            to €2,000 in coverage to protect your item.
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="bg-gray-50 py-10 px-5">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-8">
          <h2 className="text-xl md:text-2xl font-semibold mb-4 md:mb-5 text-teal-700">
            Guarantee Packages
          </h2>
          <p className="text-gray-700 md:text-lg">
            Lenders can choose a guarantee package to help protect their items.
            A small percentage of the rental earnings is deducted in exchange
            for coverage. For full details on what our guarantee includes,
            please visit our{" "}
            <Link
              to="/guarantee"
              className="text-blue-500 underline hover:font-semibold"
            >
              guarantee information page
            </Link>
            .
          </p>
        </div>

        {/* Packages Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {packages.map((pkg, i) => (
            <div
              key={i}
              className="bg-white rounded-lg shadow-lg p-4 md:p-6 flex flex-col items-center hover:shadow-xl transition duration-300 relative"
            >
              {/* Medal Icon */}
              <FontAwesomeIcon
                icon={faMedal}
                size="3x"
                style={{ color: pkg.medalColor }}
                className="mb-4"
              />

              {/* Package Name */}
              <h3 className="text-lg md:text-xl font-semibold text-gray-700 mb-2">
                {pkg.name}
              </h3>

              {/* Coverage Info */}
              <p className="text-gray-700 text-sm md:text-base text-center mb-4">
                <span className="font-semibold">{pkg.percentage}%</span> of
                earnings for up to{" "}
                <span className="font-semibold">€{pkg.coverage}</span> cover.
              </p>

              {/* Info Icon (toggles ALL scenarios at once) */}
              <button
                className="absolute top-4 right-4 text-teal-600 hover:text-teal-700"
                onClick={toggleAllScenarios}
                aria-label="Toggle scenario info"
              >
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
              </button>

              {/* Show scenario for this package if showAllScenarios is true */}
              {showAllScenarios && (
                <div className="mt-2 w-full border-t border-gray-200 pt-2">
                  {pkg.scenario}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuaranteePackages;
