import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const navigate = useNavigate();

  const faqs = [
    {
      question: "How much does it cost to list an item for rent?",
      answer: (
        <>
          It's free to list an item for rent on KitShare. We only charge a
          percentage fee based on the guarantee package you select when your
          item is rented out. This fee is deducted from your earnings, so you
          only pay when you get paid. For more details, please visit the{" "}
          <span
            onClick={() => navigate("/guarantee")}
            className="text-blue-500 underline hover:font-semibold"
          >
            KitShare Guarantee
          </span>{" "}
          page.
        </>
      ),
    },
    {
      question: "How much does KitShare charge renters per rental?",
      answer: (
        <>
          Renters are charged a fixed service fee of 10% per rental. This fee
          helps us maintain the platform and provide customer support.
        </>
      ),
    },
    {
      question: "How do I get paid?",
      answer: (
        <>
          We use Stripe and Stripe Connect to securely manage payments. Once you
          set up your Stripe Connect account, you'll have access to a dashboard
          with your transactions and payment history. When a rental is
          confirmed, the renter's payment is processed, and the funds are
          transferred to your Stripe balance the day after the rental is
          completed. Payouts to your linked bank account are initiated weekly.
        </>
      ),
    },
    {
      question: "When do I get paid from KitShare?",
      answer: (
        <>
          Funds are transferred to your Stripe balance the day after the rental
          is completed. KitShare then initiates payouts from your Stripe balance
          to your linked bank account on a weekly basis.
        </>
      ),
    },
    {
      question: "How can I trust users on this platform?",
      answer: (
        <>
          To ensure trust and security, all users are required to verify their
          ID through our trusted partner Veriff before they can rent or list
          items. Additionally, after each rental, we encourage users to leave
          reviews, which you can view on each user's profile.
        </>
      ),
    },
    {
      question: "Does KitShare provide a guarantee on rentals?",
      answer: (
        <>
          Yes, KitShare offers optional guarantee packages for lenders. When you
          receive a rental request, you can choose to insure your item by
          selecting one of our tiered guarantee packages. For more information,
          please visit our dedicated{" "}
          <span
            onClick={() => navigate("/guarantee")}
            className="text-blue-500 underline hover:font-semibold"
          >
            KitShare Guarantee
          </span>{" "}
          page. Additionally, lenders can request a security deposit for each
          rental, which needs to be collected manually.
        </>
      ),
    },
    {
      question: "Are transactions secure with KitShare?",
      answer: (
        <>
          Yes, all transactions on KitShare are secure. We use Stripe to process
          payments and transfers, ensuring that your sensitive payment
          information is handled safely. KitShare does not process or store any
          card information; all payment data is managed securely by Stripe.
        </>
      ),
    },
    {
      question: "What happens if an item gets damaged during a rental?",
      answer: (
        <>
          In the unfortunate event that your item is damaged during a rental,
          please contact us immediately at{" "}
          <a
            href="mailto:support@kitshare.ie"
            className="text-blue-500 underline"
          >
            support@kitshare.ie
          </a>{" "}
          or submit a claim request through our{" "}
          <span
            onClick={() => navigate("/claim")}
            className="text-blue-500 underline hover:font-semibold"
          >
            claim portal.
          </span>{" "}
          If you selected a guarantee package for the rental, you may be
          eligible for compensation according to the terms of the package. We
          will assist you in resolving the issue with the renter.
        </>
      ),
    },
    {
      question: "How do I list an item for rent?",
      answer: (
        <>
          Listing an item is simple. After creating your account and verifying
          your email, navigate to the "List an Item" page, fill in the required
          details about your item, upload high-quality photos, and set your
          rental price and availability. Once submitted, your item will be
          visible to renters on the platform.
        </>
      ),
    },
    {
      question:
        "Can I communicate with renters or lenders before confirming a rental?",
      answer: (
        <>
          Yes, KitShare provides an in-platform messaging system that allows
          renters and lenders to communicate safely. You can ask questions,
          discuss details, and coordinate arrangements before confirming a
          rental.
        </>
      ),
    },
    {
      question: "What is the cancellation policy?",
      answer: (
        <>
          Please refer to our{" "}
          <span
            onClick={() => navigate("/cancellation-policy")}
            className="text-blue-500 underline hover:font-semibold"
          >
            Cancellation Policy
          </span>{" "}
          page for detailed information.
        </>
      ),
    },
    {
      question: "How does the deposit system work?",
      answer: (
        <>
          Lenders have the option to request a security deposit for each rental.
          This deposit must be collected manually by the lender before the
          rental begins. We recommend using a secure payment method and
          providing a receipt to the renter. The deposit should be returned to
          the renter at the end of the rental if no issues arise.
        </>
      ),
    },
    {
      question: "Do I need to pay taxes on my earnings?",
      answer: (
        <>
          Earnings from renting out your items may be subject to taxes,
          depending on your local laws and regulations. We recommend consulting
          with a tax professional to understand your obligations. KitShare
          provides transaction history in your Stripe dashboard to assist with
          record-keeping.
        </>
      ),
    },
    {
      question: "What items can I list on KitShare?",
      answer: (
        <>
          You can list a wide range of items suitable for rental, such as
          photography equipment, tools, sports gear, and more. However,
          prohibited items include weapons, illegal goods, and any items that
          violate our terms of service. Please refer to our{" "}
          <span
            onClick={() => navigate("/terms-and-conditions")}
            className="text-blue-500 underline hover:font-semibold"
          >
            Terms and Conditions
          </span>{" "}
          for more details.
        </>
      ),
    },
    {
      question: "How does the ID verification process work?",
      answer: (
        <>
          We partner with Veriff to securely verify users' identities. During
          the verification process, you will be asked to provide a valid
          government-issued ID and a selfie. Veriff uses advanced technology to
          verify your identity, enhancing trust and safety on the platform. We
          do not store any verification information on our site.
        </>
      ),
    },
    {
      question: "What should I do if I encounter a problem with a rental?",
      answer: (
        <>
          If you experience any issues during a rental, please contact our
          support team at{" "}
          <a
            href="mailto:support@kitshare.ie"
            className="text-blue-500 underline"
          >
            support@kitshare.ie
          </a>
          . We are here to help resolve any problems and ensure a positive
          experience for all users.
        </>
      ),
    },
  ];

  const toggleFAQ = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  return (
    <div className="bg-gray-50 py-5 md:py-10">
      <div className="flex w-full justify-center">
        <h1 className="w-full text-center md:w-2/3 mb-8 text-xl md:text-2xl font-semibold text-gray-600">
          Frequently Asked Questions
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="w-11/12 md:w-8/12 lg:w-6/12">
          <div className="faq">
            {faqs.map((faq, i) => (
              <div
                key={i}
                onClick={() => toggleFAQ(i)}
                className="faq-item mb-4 p-4 rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer"
              >
                <div className="faq-question flex justify-between items-center md:text-lg font-semibold text-gray-700">
                  {faq.question}
                  <FontAwesomeIcon
                    icon={selectedQuestion === i ? faChevronUp : faChevronDown}
                    className="text-gray-500"
                  />
                </div>
                <div
                  className={`faq-answer overflow-hidden transition-all duration-300`}
                  style={{
                    maxHeight: selectedQuestion === i ? "1000px" : "0",
                  }}
                >
                  <p className="mt-2 text-gray-600 text-sm md:text-base leading-relaxed">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
