import React, { useState, useEffect, useCallback } from "react";
import algoliasearch from "algoliasearch";
import ListingCard from "../components/ListingCard";
import { useLocation } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import AlgoliaSearch from "../components/AlgoliaSearch";
import notFound from "../assets/notFound.png";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import Modal from "react-modal";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { FaLocationDot, FaX } from "react-icons/fa6";
import GoogleLocationSearch from "../components/GoogleLocationSearch";

// import debounce function
import { debounce } from "../utils/debounce";

// Initialize Algolia
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255,255,255,0.8)",
    width: "90%",
    maxWidth: "600px",
    maxHeight: "90vh",
    overflow: "auto",
    borderRadius: "8px",
    padding: "0",
  },
};

const defaultMapCenter = {
  lat: 53.349805,
  lng: -6.26031, // Default center (Dublin)
};

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

// 1) Define constant array outside of component (otherwise performance issues are reported)
const LIBRARIES = ["places"];

const SearchResults = () => {
  // State for search results and pagination
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  // State for location
  const [selectedGeolocation, setSelectedGeolocation] =
    useState(defaultMapCenter);
  const [selectedLocation, setSelectedLocation] = useState({
    formattedAddress: "Dublin",
    addressComponents: {},
  });

  // State for map modal
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [locationInputValue, setLocationInputValue] = useState("");

  // ----- State for controlling radius -----
  // Radius stored in metres. For example, 50,000 = 50 km.
  const [searchRadius, setSearchRadius] = useState(50000);

  const location = useLocation();
  const query = new URLSearchParams(location.search).get("q");
  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  // Load Google Maps
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES,
  });

  // Set initial geolocation based on user or defaults
  useEffect(() => {
    if (userDoc && userDoc._geoloc) {
      setSelectedGeolocation({
        lat: userDoc._geoloc.lat,
        lng: userDoc._geoloc.lng,
      });
      setSelectedLocation({
        formattedAddress: userDoc.formattedAddress,
        addressComponents: userDoc.addressComponents,
      });
      setLocationInputValue(userDoc.formattedAddress);
    } else {
      setSelectedGeolocation(defaultMapCenter);
      setSelectedLocation({
        formattedAddress: "Dublin",
        addressComponents: {},
      });
      setLocationInputValue("Dublin");
    }
  }, [userDoc]);

  // 1) Create the main function that performs the Algolia search
  const performSearch = async (q, pageNum, lat, lng, radiusMeters) => {
    setIsLoading(true);
    let indexName = "kitshare_listings";
    if (!q) {
      indexName = "kitshare_listings_createdAt_desc";
    }
    const index = searchClient.initIndex(indexName);

    try {
      const searchParams = {
        getRankingInfo: true,
        page: pageNum,
        hitsPerPage: 16,
        aroundLatLng: `${lat},${lng}`,
        aroundRadius: radiusMeters,
        filters: "isActive:true",
      };

      const { hits, nbPages } = await index.search(q || "", searchParams);

      const hitsWithAddedId = hits.map((hit) => ({
        ...hit,
        id: hit.objectID,
      }));

      if (pageNum === 0) {
        setSearchResults(hitsWithAddedId);
      } else {
        setSearchResults((prev) => [...prev, ...hitsWithAddedId]);
      }
      setTotalPages(nbPages);
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // 2) Wrap `performSearch` with your existing `debounce`
  const debouncedSearch = useCallback(
    debounce(
      (q, pageNum, lat, lng, radiusMeters) => {
        performSearch(q, pageNum, lat, lng, radiusMeters);
      },
      500 // 500ms delay
    ),
    []
  );

  // 3) Whenever relevant state changes, call debouncedSearch
  useEffect(() => {
    debouncedSearch(
      query,
      page,
      selectedGeolocation.lat,
      selectedGeolocation.lng,
      searchRadius
    );
  }, [query, page, selectedGeolocation, searchRadius, debouncedSearch]);

  // "Show More" for pagination
  const handleShowMore = () => {
    if (page < totalPages - 1) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Handle location changes (from GoogleLocationSearch)
  const handleLocationChange = (location) => {
    if (location) {
      setSelectedGeolocation({
        lat: location.geolocation.lat,
        lng: location.geolocation.lng,
      });
      setSelectedLocation({
        formattedAddress: location.formattedAddress,
        addressComponents: location.addressComponents,
      });
      setLocationInputValue(location.formattedAddress);
      setPage(0);
    } else {
      setSelectedGeolocation(defaultMapCenter);
      setSelectedLocation({
        formattedAddress: "Dublin",
        addressComponents: {},
      });
      setLocationInputValue("Dublin");
    }
  };

  // Handle clicking on the map
  const handleMapClick = (event) => {
    setSelectedPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  // Confirm location from the map
  const handleConfirmLocation = () => {
    if (selectedPosition) {
      setSelectedGeolocation(selectedPosition);
      setSelectedLocation({
        formattedAddress: "Custom Location",
        addressComponents: {},
      });
      setLocationInputValue("");
      setPage(0);
      setIsMapModalOpen(false);
    }
  };

  // ----- Range slider change -----
  const handleRadiusSliderChange = (e) => {
    const newRadius = e.target.value; // e.g. '25' => 25 km
    setSearchRadius(newRadius * 1000); // Convert to meters
    setPage(0); // Reset to page 0 so we see fresh results
  };

  return (
    <div className="mx-auto">
      <div className="bg-gray-100 pt-4 pb-2 mb-5">
        {/* Search Bar */}
        <div className="flex justify-center">
          <div className="w-full md:w-5/6 xl:w-1/2">
            <AlgoliaSearch />
          </div>
        </div>

        {/* Location & Map Section */}
        <div className="flex justify-center mb-4 px-4">
          <div className="w-full md:w-5/6 xl:w-1/2 bg-white p-4 rounded-md shadow-sm">
            <div className="flex items-center mb-3">
              <FaLocationDot className="w-5 h-5 mr-2 text-accent" />
              <h2 className="text-base md:text-lg font-semibold text-gray-700">
                Change Location
              </h2>
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-4 md:gap-0">
              <div className="w-full sm:w-2/3">
                {isLoaded ? (
                  <GoogleLocationSearch
                    onLocationSelect={handleLocationChange}
                    isLoaded={isLoaded}
                    inputValue={locationInputValue}
                    setInputValue={setLocationInputValue}
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </div>
              <button
                onClick={() => setIsMapModalOpen(true)}
                className="px-4 btn-primary ml-5 text-sm md:text-base font-semibold"
              >
                <div className="flex justify-center items-center gap-1 px-5 md:px-2">
                  <FaLocationDot />
                  <p>Choose on Map</p>
                </div>
              </button>
            </div>

            {/* Show selected location + slider */}
            {selectedLocation && (
              <div className="mt-3 bg-gray-50 p-3 rounded-md">
                <p className="text-gray-700 text-xs sm:text-sm mb-3">
                  <span className="font-medium">Search Focus Location:</span>{" "}
                  {selectedLocation.formattedAddress}
                </p>

                {/* Range Slider */}
                <div className="flex flex-col">
                  <p className="text-xs sm:text-sm font-medium text-gray-700 mb-2">
                    Search Radius:<span> {searchRadius / 1000} km</span>
                  </p>
                  <input
                    id="radiusSlider"
                    type="range"
                    min={5}
                    max={100}
                    step={5}
                    value={searchRadius / 1000} // from meters to km
                    onChange={handleRadiusSliderChange}
                    className="w-full cursor-pointer"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Map Modal */}
      <Modal
        isOpen={isMapModalOpen}
        onRequestClose={() => setIsMapModalOpen(false)}
        contentLabel="Select Location on Map"
        ariaHideApp={false}
        style={customStyles}
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-between gap-5 items-center p-4 border-b">
            <h2 className="text-lg font-semibold">Select Location on Map</h2>
            <button
              onClick={() => setIsMapModalOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <FaX />
            </button>
          </div>
          <div className="flex-1">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={selectedGeolocation}
                zoom={8}
                onClick={handleMapClick}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                }}
              >
                {selectedPosition && <Marker position={selectedPosition} />}
              </GoogleMap>
            ) : null}
          </div>
          <div className="flex justify-center p-4 border-t">
            <button
              onClick={handleConfirmLocation}
              className="px-4 btn-primary"
              disabled={!selectedPosition}
            >
              Confirm Location
            </button>
          </div>
        </div>
      </Modal>

      {/* Search Results */}
      <div className="px-2">
        {query?.length > 0 ? (
          <h2 className="text-lg text-gray-700 md:text-xl font-semibold mb-4">
            Search Results for "{query}"
          </h2>
        ) : null}

        {isLoading && page === 0 ? (
          <div className="flex justify-center items-center h-64">
            <SyncLoader size={15} color="#14b8a6" />
          </div>
        ) : (
          <div className="flex w-full justify-center">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 mb-5">
              {searchResults.length > 0 ? (
                searchResults.map((listingDetails, index) => (
                  <ListingCard listingDetails={listingDetails} key={index} />
                ))
              ) : (
                <div className="w-screen">
                  <div className="flex flex-col justify-center items-center h-64">
                    <img
                      src={notFound}
                      className="w-32 md:w-64 mb-4"
                      alt="notFound"
                    />
                    <p>No results found for "{query}".</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {page < totalPages - 1 && (
          <div className="flex justify-center mt-6">
            <button onClick={handleShowMore} className="btn-continue px-5 mb-5">
              Show More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
