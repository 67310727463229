import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import CheckListModal from "./CheckListModal";
import formatCurrency from "../../utils/formatCurrency";
import { toast } from "react-toastify";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";

const AvailabilityConfirmedRenter = ({
  message,
  onOpenModal,
  authUser,
  userDoc,
  rentalRequestChatId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  // const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);

  const navigate = useNavigate();

  const {
    listingTitle,
    startDate,
    endDate,
    totalCostCents,
    numberOfDays,
    listingImage,
  } = message;

  let depositCents;

  if (message.deposit) {
    depositCents = message.depositCents;
  }

  // Function to check which modal should be opened for the renter, based upon the verification status of their account
  const showVerificationOrChecklistModal = () => {
    if (userDoc?.veriff?.decision !== "approved" || !authUser.emailVerified) {
      console.log("test");
      // setIsVerificationModalOpen(true);
      toast.error(
        "You need to verify your email and ID before you can confirm a rental.",
        { autoClose: 5000 }
      );
    }
    setIsChecklistModalOpen(true);
  };

  const handleNavigateToRentalPaymentPage = () => {
    navigate("/confirm-rental", {
      state: {
        message,
        rentalRequestChatId,
      },
    });
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  const formattedStartDate =
    firestoreTimestampToDate(startDate).toLocaleDateString();
  const formattedEndDate =
    firestoreTimestampToDate(endDate).toLocaleDateString();

  return (
    <div className="">
      {/* {isVerificationModalOpen && (
        <VerificationModal
          isOpen={isVerificationModalOpen}
          onClose={() => setIsVerificationModalOpen(false)}
          idVerified={currentUser?.idVerified}
          currentUser={currentUser}
        />
      )} */}

      {userDoc?.veriff?.decision === "approved" ? (
        <CheckListModal
          isOpen={isChecklistModalOpen}
          onClose={() => setIsChecklistModalOpen(false)}
          handleConfirm={handleNavigateToRentalPaymentPage}
        />
      ) : null}
      <h2 className="text-center font-bold">Availability Request</h2>
      {/* <p className="text-center my-5 new-line">{content}</p> */}
      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md"></img>
        <p className="font-semibold mt-2 text-sm md:text-base">
          {listingTitle}
        </p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="text-sm md:text-base">
          <span className="font-bold">Total Cost:</span> €
          {formatCurrency(totalCostCents)}
        </p>
        <p className="text-sm md:text-base">
          <span className="font-semibold">Duration:</span> {numberOfDays}{" "}
          {numberOfDays === 1 ? "day" : "days"}
        </p>
        <p className="text-sm md:text-base text-center">{`${
          formattedStartDate === formattedEndDate
            ? formattedStartDate
            : `${formattedStartDate} - ${formattedEndDate}`
        }`}</p>
        {depositCents && (
          <p className="text-sm md:text-base">
            <span className="font-bold">Required deposit:</span> €
            {formatCurrency(depositCents)}
          </p>
        )}
      </div>
      <p className="text-center text-base md:text-lg my-4 font-bold text-emerald-500">
        Item is available
      </p>
      <p className="text-xs md:text-sm text-center">
        Rental is not confirmed, you now need to pay and verify by clicking
        "Confirm Rental" below.
      </p>
      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button
          className="p-2 btn-confirm"
          onClick={() => showVerificationOrChecklistModal()}
        >
          {isLoading ? (
            <BeatLoader />
          ) : (
            <p className="text-sm md:text-base">
              {" "}
              <FontAwesomeIcon icon={faCheck} /> Confirm Rental
            </p>
          )}
        </button>
        <button
          onClick={() => onOpenModal()}
          className="p-2 btn-delete text-sm md:text-base"
        >
          <FontAwesomeIcon icon={faCancel} /> Cancel Request
        </button>
      </div>
      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>{" "}
      </div>
    </div>
  );
};

export default AvailabilityConfirmedRenter;
