import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import { Elements } from "@stripe/react-stripe-js";
import { doc, getDoc } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import CheckoutForm from "../components/CheckoutForm"; // make sure the path is correct
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import formatCurrency from "../utils/formatCurrency";

// Load Stripe with your public key
// const stripePromise = loadStripe(
//   process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY
// );
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY
);

const ConfirmRental = () => {
  const [listingLenderDetails, setListingLenderDetails] = useState("");
  const [depositCents, setDepositCents] = useState(0);

  const location = useLocation();

  const getListingLenderDetails = async (listingLenderUid) => {
    const lenderDocRef = doc(db, "users", listingLenderUid);
    const lenderSnap = await getDoc(lenderDocRef);
    if (lenderSnap.exists()) {
      setListingLenderDetails(lenderSnap.data());
    } else {
      setListingLenderDetails({ username: null, location: null });
    }
  };

  useEffect(() => {
    const { lenderUid } = location.state.message;
    getListingLenderDetails(lenderUid);
    if (location.state.message) {
      setDepositCents(location.state.message.depositCents);
    }
  }, [location]);

  const { message, rentalRequestChatId } = location.state;
  const {
    listingTitle,
    listingImage,
    numberOfDays,
    rateCents,
    rentalCostCents,
    serviceFeeCents,
    startDate,
    endDate,
    totalCostCents,
  } = message;

  const formattedStartDate =
    firestoreTimestampToDate(startDate)?.toDateString() || "Loading...";
  const formattedEndDate =
    firestoreTimestampToDate(endDate)?.toDateString() || "Loading...";

  // Options to pass to create stripe elements ()
  const options = {
    mode: "payment",
    currency: "eur",
    amount: totalCostCents,
  };

  return (
    <div>
      <div className="flex w-full justify-center">
        <h1 className="md:w-2/3 text-gray-700 text-center my-5 text-lg md:text-xl font-bold header__center">
          Confirm Your Rental
        </h1>
      </div>

      {/* Main Flex Container */}
      <div className="flex flex-col-reverse sm:flex-row justify-center gap-2 md:gap-10">
        {/* Left Hand Side - Rental Details */}

        <div className="w-full sm:w-1/3 p-3 sm:p-0">
          <p className="font-semibold text-lg text-gray-700 md:text-xl mb-2">
            Rental Details
          </p>
          {/* Listing pricing details */}
          <div className="bg-gray-100 shadow-md flex flex-col gap-3 rounded-md p-2">
            <p className="text-sm md:text-base">
              <span className="font-semibold text-gray-700">
                Number of days:{" "}
              </span>{" "}
              {numberOfDays}
            </p>
            <p className="text-sm md:text-base">
              <span className="font-semibold text-gray-700">Dates:</span>{" "}
              {formattedStartDate} - {formattedEndDate}
            </p>
            {depositCents && depositCents > 0 ? (
              <p className="text-sm md:text-base">
                <span className="font-semibold text-gray-700">
                  Required Deposit:
                </span>{" "}
                €{formatCurrency(depositCents)}
              </p>
            ) : null}
            <p className="text-sm md:text-base">
              <span className="font-semibold text-gray-700">Rate:</span> €
              {formatCurrency(rateCents)}
            </p>

            <p className="text-sm md:text-base border-t-2 border-gray-400 pt-4">
              €{formatCurrency(rateCents)} x {numberOfDays} days = €
              {formatCurrency(rentalCostCents)}
            </p>
            <p className="text-sm md:text-base">
              <span className="font-semibold text-gray-700">
                Service Fee (10%):
              </span>{" "}
              €{formatCurrency(serviceFeeCents)}
            </p>
            {/* <p>
              <span className="font-semibold">Service Fee Total (10%):</span> €
              {formatCurrency(serviceFeeCents)}
            </p>

            <p className="text-sm">
              - Service Fee: €{formatCurrency(serviceFeeCents * 0.77)}
            </p>
            <p className="text-sm">
              - VAT (23%): €{formatCurrency(serviceFeeCents * 0.23)}
            </p> */}
            <p className="border-t-2 border-gray-400 pt-4">
              <span className="font-semibold text-gray-700">Total:</span> €
              {formatCurrency(totalCostCents)}
            </p>
          </div>

          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              message={message}
              rentalRequestChatId={rentalRequestChatId}
              listingLenderDetails={listingLenderDetails}
            />
          </Elements>

          {/* Cancellation policy */}
          <div className="bg-gray-100 p-2 rounded-md shadow-md mb-10">
            <div className="flex flex-col ">
              <h2 className="font-semibold text-base md:text-lg text-gray-700">
                Cancellation Policy
              </h2>
              <p className="text-sm md:text-base">
                Our goal is to provide a flexible and fair cancellation policy
                for our customers while ensuring the sustainability of our
                rental services. Please review our cancellation terms below:
              </p>
              <h3 className="font-semibold mt-5 text-base md:text-lg text-gray-700">
                Cancellation Terms:
              </h3>
              <ul className="flex flex-col gap-1 text-sm md:text-base">
                <li>
                  <span className="font-semibold text-gray-700">
                    7 days or more before rental start date:
                  </span>{" "}
                  No cancellation fee.
                </li>
                <li>
                  <span className="font-semibold text-gray-700">
                    Less than 7 days but more than 1 day before rental start
                    date:
                  </span>{" "}
                  10% cancellation fee.
                </li>
                <li>
                  <span className="font-semibold text-gray-700">
                    1 day or less before rental start date:
                  </span>{" "}
                  20% cancellation fee.
                </li>
                <li>
                  <span className="font-semibold">Special Circumstances</span>:
                  Full refunds or waivers of the cancellation fee may be
                  considered for documented emergencies or unforeseen events,
                  evaluated on a case-by-case basis.
                </li>
              </ul>
              <p className="text-sm md:text-base mt-2">
                We believe this policy strikes a balance between flexibility for
                our customers and the necessary safeguards for our operations.
                If you have any questions or require assistance, please contact
                our support team.
              </p>
              <p className="mt-4 text-sm md:text-base">
                <strong>Contact Us</strong>: info@kitshare.ie
              </p>
            </div>
          </div>
        </div>

        {/* Right Hand Side - Listing and User Details */}
        <div className="flex flex-col p-2">
          {/* Listing Details */}
          <div className="flex flex-col justify-center align-center items-center">
            <h2 className="font-semibold text-lg md:text-xl mb-4 text-gray-700">
              {listingTitle}
            </h2>
            <img src={listingImage} className="w-48 mb-5" alt="listingImage" />
          </div>
          {/* User details */}
          <div className="flex justify-between bg-gray-100 rounded-md p-2 shadow-md">
            <p className="text-sm md:text-base mb-2">
              <span className="font-semibold text-gray-700">
                {listingLenderDetails?.username ?? ""}
              </span>{" "}
              in {listingLenderDetails?.formattedAddress ?? ""}
            </p>
            <div className="ml-5 flex items-center mb-2 gap-2">
              <p className="text-xs md:text-sm">
                {listingLenderDetails.averageReviewRating
                  ? listingLenderDetails.averageReviewRating
                  : "N/A"}{" "}
                <FontAwesomeIcon icon={faStar} color="gold" />
              </p>
              <p className="text-xs md:text-sm">
                {" "}
                (
                {listingLenderDetails.reviewCount > 0
                  ? listingLenderDetails.reviewCount
                  : 0}
                )
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRental;
