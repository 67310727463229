import React, { useState, useEffect } from "react";
import Modal from "react-modal"; // or react-modal
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

import { db } from "../firebase.config";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";

import ReviewForm from "../components/ReviewForm";
import LoginFormModal from "../components/LoginFormModal";

// Make sure you've set up react-modal's app root somewhere, e.g. Modal.setAppElement("#root");

const ReviewPage = () => {
  const [revieweeDetails, setRevieweeDetails] = useState(null);
  const [listingDetails, setListingDetails] = useState(null);
  const [rentalRequestDetails, setRentalRequestDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  // For the login modal
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const { rentalRequestId, revieweeUid } = useParams();
  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  // 1) On mount, fetch the necessary documents.
  // But if the user is not logged in, we can still fetch data in read-only fashion.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const rentalRequestRef = doc(db, "completed-rentals", rentalRequestId);
        const rentalRequestDoc = await getDoc(rentalRequestRef);

        if (!rentalRequestDoc.exists()) {
          toast.error("Rental request not found");
          setLoading(false);
          return;
        }

        const rentalData = rentalRequestDoc.data();
        setRentalRequestDetails(rentalData);

        // Fetch listing details
        const listingId = rentalData.listingId;
        const listingRef = doc(db, "listings", listingId);
        const listingDoc = await getDoc(listingRef);
        if (listingDoc.exists()) {
          setListingDetails({
            ...listingDoc.data(),
            id: listingDoc.id,
          });
        } else {
          toast.error("Listing not found");
        }

        // Fetch reviewee details
        const userRef = doc(db, "users", revieweeUid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setRevieweeDetails(userDoc.data());
        } else {
          toast.error("User not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [rentalRequestId, revieweeUid]);

  // 2) If the user is NOT logged in, open the login modal
  //    We can do that automatically on page load or only if they click "Submit Review."
  useEffect(() => {
    if (!loading && !authUser) {
      setIsLoginModalOpen(true);
    }
  }, [authUser, loading]);

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <BeatLoader color="#109588" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 mb-5">
      <h1 className="text-center my-5 text-xl font-semibold text-gray-700">
        Review Your Rental Experience
      </h1>

      {/* Listing Details */}
      <div className="w-full flex justify-center mb-10">
        <div className="flex flex-col w-full sm:w-2/3 md:w-1/2 lg:w-1/3 items-center rounded-md shadow-lg p-4">
          <h2 className="font-semibold my-3 text-lg md:text-xl text-gray-700 text-center">
            {listingDetails && listingDetails.title}
          </h2>
          <img
            src={listingDetails?.imageURLs ? listingDetails.imageURLs[0] : ""}
            alt="Listing"
            className="w-full h-auto max-w-xs object-cover rounded-md mb-2"
          />
          <div className="flex flex-col text-gray-600 gap-2 mt-4">
            <p>
              <span className="font-bold">Duration:</span>{" "}
              {rentalRequestDetails?.numberOfDays}{" "}
              {rentalRequestDetails?.numberOfDays === 1 ? "day" : "days"}
            </p>
            <p>
              <span className="font-bold">From:</span>{" "}
              {rentalRequestDetails &&
                firestoreTimestampToDate(
                  rentalRequestDetails.startDate
                )?.toLocaleDateString()}
            </p>
            <p>
              <span className="font-bold">To:</span>{" "}
              {rentalRequestDetails &&
                firestoreTimestampToDate(
                  rentalRequestDetails.endDate
                )?.toLocaleDateString()}
            </p>
            <p className="text-center text-teal-500 font-semibold mt-2">
              {revieweeDetails?.username}
            </p>
          </div>
        </div>
      </div>

      {/* 
        3) If the user is logged in, show the form. 
           If not, show something else (like "Please log in first").
      */}
      {authUser ? (
        <ReviewForm
          rentalRequestId={rentalRequestId}
          rentalRequestDetails={rentalRequestDetails}
          revieweeUid={revieweeUid}
          revieweeDetails={revieweeDetails}
          listingDetails={listingDetails}
        />
      ) : (
        <div className="text-center text-gray-600">
          <p>Please log in to submit your review.</p>
        </div>
      )}

      {/* 
         4) The login modal (only if user is not logged in). 
          
      */}

      <LoginFormModal onClose={closeLoginModal} />
    </div>
  );
};

export default ReviewPage;
