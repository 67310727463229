import React from "react";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="max-w-4xl mx-auto py-6 px-4">
        {/* 1. Title / Introduction */}
        <h1 className="text-xl text-center md:text-2xl font-semibold mb-8 md:header__center text-gray-700">
          KITSHARE PRIVACY POLICY
        </h1>

        {/* Introduction */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            1. Introduction
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            Welcome to KitShare (“<strong>KitShare</strong>,” “
            <strong>we</strong>,” “<strong>us</strong>,” or “
            <strong>our</strong>”). We value your privacy and are committed to
            protecting your personal data. This Privacy Policy explains how we
            collect, use, and share your data when you use our services.
          </p>
          <p className="text-sm md:text-base text-gray-700 mt-2">
            By using KitShare, you agree to the practices described in this
            Policy. If you have any questions, please contact us at{" "}
            <a
              href="mailto:support@kitshare.ie"
              className="text-blue-500 underline"
            >
              support@kitshare.ie
            </a>
            .
          </p>
          {/* If you have a registered business address or company number, list it here. */}
        </section>

        {/* 2. Information We Collect */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            2. Information We Collect
          </h2>
          <p className="text-sm md:text-base text-gray-700 mb-4">
            We collect various types of information to provide and improve our
            services. Below is an overview of the data we gather:
          </p>
          <ul className="list-disc list-inside space-y-2 text-sm md:text-base text-gray-700 mb-4">
            <li>
              <strong>Account & Profile Data:</strong> Your first name, last
              name, username, email address, and phone number (if provided).
              {/* Add phone number only if relevant to your actual sign-up flow. */}
            </li>
            <li>
              <strong>Location Data:</strong> A general suburb/town to show
              items near your area. We <em>do not</em> collect exact home
              addresses or display them publicly.
            </li>
            <li>
              <strong>Verification & Security Info:</strong> If you go through
              our ID verification process (via a 3rd party Veriff), we process
              necessary identification data. This verification data is solely
              stored within Veriff.
              {/* Adjust depending on your actual ID verification policy. */}
            </li>
            <li>
              <strong>Usage Data:</strong> May include device information, IP
              addresses, or log data collected by Firebase. This helps us ensure
              site security and improve functionality.{" "}
              {/* Modify to reflect your actual usage. */}
            </li>
            <li>
              <strong>Communication Data:</strong> We store your email address
              for transactional emails (e.g., confirmations, notifications) via
              SendGrid. We do not sell your email to third parties.
            </li>
          </ul>
          {/* If you store or use cookies, mention them here or link to a separate Cookies Policy. */}
        </section>

        {/* 3. How We Use Your Information */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            3. How We Use Your Information
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            We use your data to:
          </p>
          <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
            <li>
              <strong>Provide & Personalize Services:</strong> Create and manage
              user accounts, show relevant items near your location, and tailor
              search results.
            </li>
            <li>
              <strong>Communicate:</strong> Send emails about your account, such
              as confirmations, updates, or important notices.
            </li>
            <li>
              <strong>Verify Identities & Prevent Fraud:</strong> We use
              third-party verification partners to ensure a safe marketplace.
            </li>
            <li>
              <strong>Legal & Safety Requirements:</strong> Comply with legal
              obligations, protect user safety, and enforce our Terms.
            </li>
          </ul>
          {/* If using the data for marketing, mention it and the user’s right to opt out. */}
        </section>

        {/* 4. Legal Bases for Processing (If Applicable) */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            4. Legal Bases for Processing
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            {/* Only include this if you must comply with GDPR or similar. */}
            Where required by law (e.g., in the EEA or UK), we process your data
            on the following bases:
          </p>
          <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
            <li>
              <strong>Contract:</strong> To provide the services you request.
            </li>
            <li>
              <strong>Legitimate Interests:</strong> For site security,
              improvements, and business operations.
            </li>
            <li>
              <strong>Consent:</strong> Where you choose to opt in (e.g.,
              optional marketing communications).
            </li>
            <li>
              <strong>Legal Obligation:</strong> Compliance with laws, such as
              record-keeping or responding to lawful requests.
            </li>
          </ul>
        </section>

        {/* 5. Sharing Your Information */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            5. Sharing Your Information
          </h2>
          <p className="text-sm md:text-base text-gray-700 mb-4">
            We do not sell your personal data. However, we may share it in the
            following ways:
          </p>
          <ul className="list-disc list-inside text-sm md:text-base text-gray-700 space-y-2">
            <li>
              <strong>Service Providers:</strong> We store data on Firebase and
              send emails via SendGrid. These providers process data on our
              behalf.
            </li>
            <li>
              <strong>With Other Users:</strong> When you engage in rental
              transactions, essential details (e.g., your username) may be
              shared.
            </li>
            <li>
              <strong>Legal Obligations or Rights:</strong> If required by law
              or necessary to protect user or public safety.
            </li>
          </ul>
        </section>

        {/* 6. Data Retention */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            6. Data Retention
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            We retain your personal data as long as your account is active or as
            needed to provide services. We may also keep certain information to
            comply with legal obligations or resolve disputes.
          </p>
        </section>

        {/* 7. Data Security */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4">
            7. Data Security
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            We implement security measures such as encryption in transit and
            restricted access. While we strive to protect your data, no system
            can guarantee 100% security.
          </p>
        </section>

        {/* 8. Your Rights */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            8. Your Rights
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            Depending on your jurisdiction, you may have the right to:
          </p>
          <ul className="list-disc list-inside text-sm md:text-base text-gray-700 mb-4 space-y-2">
            <li>
              <strong>Access & Correct:</strong> Request a copy of your data or
              correct inaccuracies.
            </li>
            <li>
              <strong>Delete:</strong> Ask us to delete your data (unless we're
              required to keep it).
            </li>
            <li>
              <strong>Object to Processing:</strong> For marketing or when based
              on legitimate interests.
            </li>
            <li>
              <strong>Withdraw Consent:</strong> If processing is based on
              consent.
            </li>
          </ul>
          <p className="text-sm md:text-base text-gray-700">
            To exercise any rights, contact us at{" "}
            <a
              href="mailto:support@kitshare.ie"
              className="text-blue-500 underline"
            >
              support@kitshare.ie
            </a>
            . We may need to verify your identity before complying with your
            request.
          </p>
        </section>

        {/* 9. Cookies & Tracking Technologies */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            9. Cookies & Tracking Technologies
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            {/* Only mention if you use cookies or similar. */}
            We may use cookies and similar technologies to provide and improve
            our services. You can control cookies via your browser settings, but
            disabling them may affect functionality.
          </p>
        </section>

        {/* 10. Changes to This Policy */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            10. Changes to This Policy
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            We may update this Privacy Policy from time to time. If we make
            significant changes, we will notify you via email or within the app.
            Your continued use of KitShare after any changes indicates your
            acceptance of the updated Policy.
          </p>
        </section>

        {/* 11. Contact Us */}
        <section className="mb-8">
          <h2 className="text-lg md:text-2xl font-semibold mb-2 md:mb-4 text-gray-700">
            11. Contact Us
          </h2>
          <p className="text-sm md:text-base text-gray-700">
            If you have any questions about this Privacy Policy, please contact
            us at{" "}
            <a
              href="mailto:support@kitshare.ie"
              className="text-blue-500 underline"
            >
              support@kitshare.ie
            </a>
            . If you are located in the European Economic Area (EEA) or UK and
            believe your rights under GDPR are not being met, you can lodge a
            complaint with your local supervisory authority.
          </p>
          {/* You might also provide a physical address if you have one. */}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
