import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ authUser }) => {
  return (
    <>
      <footer className="bg-gradient-to-r from-gray-500 via-gray-500 to-gray-600">
        <div className="max-w-screen-lg mx-auto px-4 pt-6 pb-3 md:grid md:grid-cols-5 gap-8 md:gap-16 sm:grid sm:grid-cols-2 sm:gap-8">
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              My Profile
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/my-profile"
            >
              My Profile
            </Link>
            {authUser && (
              <>
                <Link
                  className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
                  to="/my-listings"
                >
                  Listings
                </Link>
                <Link
                  className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
                  to="/my-bookings"
                >
                  Bookings
                </Link>
                <Link
                  className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
                  to="/my-rentals"
                >
                  Rentals
                </Link>
                <Link
                  className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
                  to="/favourites"
                >
                  Favourites
                </Link>
                <Link
                  className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
                  to="/lenders-hub"
                >
                  Lenders Hub
                </Link>
              </>
            )}
          </div>
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              Rent
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/new-listing"
            >
              List an Item
            </Link>
          </div>
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              Contact
            </h2>

            <Link
              className="text-sm sm:text-base block no-underline mb-3 sm:mb-2 text-white hover:font-semibold"
              to="/contact-us"
            >
              Contact Us
            </Link>
          </div>
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              Information
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/terms-and-conditions"
            >
              Terms & Conditions
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/guarantee"
            >
              Guarantee
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/claim"
            >
              Submit a Claim
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              Social
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="https://www.instagram.com/kitshareie/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </Link>
          </div>
        </div>
        {/* Copyright */}
        <div className="pt-3 bg-white">
          <p className="text-xs sm:text-base text-center">
            Copyright &copy; KitShare Pty Ltd 2024 - All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
