import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase.config";
import {
  addDoc,
  collection,
  serverTimestamp,
  arrayUnion,
  doc,
  updateDoc,
  increment,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import { BeatLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import stripeLogo from "../assets/stripeLogo.png";
import formatCurrency from "../utils/formatCurrency";
import { calculateTotalCostCents } from "../utils/costCalculations";

const CheckoutForm = ({
  message,
  rentalRequestChatId,
  listingLenderDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [paymentStatusMessage, setPaymentStatusMessage] = useState("");
  const [listingDetails, setListingDetails] = useState({});
  const navigate = useNavigate();
  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  const {
    listingId,
    listingTitle,
    numberOfDays,
    lenderUid,
    rateCents,
    rentalCostCents,
    serviceFeeCents,
    startDate,
    endDate,
    totalCostCents,
    guaranteePackage,
  } = message;

  const createPaymentIntent = async () => {
    try {
      const response = await fetch(
        "https://createpaymentintent-createpaymentintent-iz3msmwhcq-nw.a.run.app",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: `Rental Payment for ${listingTitle}`,
            email: authUser.email,
            renterUid: authUser.uid,
            lenderUid,
            listingId,
            startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
            endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
            numberOfDays,
            rateCents,
            rentalCostCents,
            totalCostCents,
            serviceFeeCents,
          }),
        }
      );

      const data = await response.json();
      if (data.clientSecret) {
        return data.clientSecret;
      } else {
        throw new Error("Failed to initialize payment.");
      }
    } catch (error) {
      console.error("Error creating payment intent:", error);
      toast.error("Failed to initialize payment.");
      throw error;
    }
  };

  useEffect(() => {
    const fetchListingDetails = async () => {
      const listingRef = doc(db, "listings", listingId);
      const listingDoc = await getDoc(listingRef);
      if (listingDoc.exists) {
        setListingDetails(listingDoc.data());
        // return listingDoc.data().bookings || [];
      }
      return;
    };

    fetchListingDetails();
  }, [listingId]);

  // Helper function used by isBookingAvailable to determine if dates overlap
  const checkDateOverlap = (startDate1, endDate1, startDate2, endDate2) => {
    const start1 = startDate1.seconds;
    const end1 = endDate1.seconds;
    const start2 = startDate2.seconds;
    const end2 = endDate2.seconds;
    return start1 <= end2 && start2 <= end1;
  };

  // Helper function to check if booking is still available
  const isBookingAvailable = (existingBookings, newStartDate, newEndDate) => {
    for (const booking of existingBookings) {
      const { startDate, endDate } = booking;
      if (checkDateOverlap(newStartDate, newEndDate, startDate, endDate)) {
        toast.error(
          "These dates are already booked. Please choose different dates.",
          { autoClose: 6000 }
        );
        return false;
      }
    }
    return true;
  };

  // Function used for incrementing booking count or rental count for lender/renter
  const incrementCounterForUser = async (userId, field) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      [field]: increment(1),
    });
  };

  // Function used to create booking/rental document for lender/renter
  const createDocumentForUser = async (userId, collectionName, data) => {
    const docRef = await addDoc(
      collection(db, "users", userId, collectionName),
      data
    );
    return docRef.id;
  };

  const createRentalBookingInListingDocument = async (bookingId) => {
    const bookingDetails = {
      bookingId,
      userUid: authUser.uid,
      startDate,
      guaranteePackage,
      endDate,
      createdAt: new Date(),
    };
    const listingRef = doc(db, "listings", listingId);
    await updateDoc(listingRef, {
      bookings: arrayUnion(bookingDetails),
    });
  };

  const updateRentalRequestStatus = async () => {
    // Update rental-request document to status === confirmed
    const rentalRef = doc(db, "rental-requests", rentalRequestChatId);
    await updateDoc(rentalRef, { status: "confirmed" });

    // Update rental-request message document status to "rental-confirmed"
    const messageRef = doc(
      db,
      "rental-requests",
      rentalRequestChatId,
      "messages",
      message.id
    );
    await updateDoc(messageRef, {
      type: "rental-confirmed",
      createdAt: serverTimestamp(),
    });
  };

  // Function to check if new rental dates overlap with existing rentals
  const checkIfNewRentalOverlapsWithExistingRentals = async () => {
    // get renter's rentals collection
    const rentalsRef = collection(db, "users", authUser.uid, "rentals");
    const snapshot = await getDocs(rentalsRef);
    if (snapshot.empty) {
      return false;
    }
    // check if any of the renters existing rentals overlap with the new rental dates
    snapshot.forEach((doc) => {
      const { startDate: existingStartDate, endDate: existingEndDate } =
        doc.data();
      if (
        checkDateOverlap(startDate, endDate, existingStartDate, existingEndDate)
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  const sendRentalConfirmedEmailToRenter = async () => {
    // TEMPLATE WITH VAT
    // const body = JSON.stringify({
    //   renterEmail: currentUser.email,
    //   firstName: currentUser.firstName,
    //   listingTitle,
    //   startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
    //   endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
    //   duration: numberOfDays,
    //   rentalCostCents: rentalCostCents,
    //   serviceFeeCentsGross: serviceFeeCents,
    //   serviceFeeCentsNet: serviceFeeCents * 0.77,
    //   vatAmountCents: serviceFeeCents * 0.23,
    //   totalAmountCentsGross: totalCostCents,
    // });

    // TEMPLATE WITHOUT VAT
    const body = JSON.stringify({
      renterEmail: authUser.email,
      firstName: userDoc.firstName,
      listingTitle,
      startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
      endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
      duration: numberOfDays,
      rentalCostCents,
      serviceFeeCents,
      totalCostCents,
    });

    try {
      const response = await fetch(
        `https://rentalconfirmedrenteremail-rentalconfirmedrentere-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );
      const data = await response.json();
    } catch (error) {
      toast.error("Failed to send confirmation email to renter.", {
        autoClose: 3000,
      });
    }
  };

  const sendRentalConfirmedEmailToLender = async () => {
    const startDateObj = firestoreTimestampToDate(startDate);
    const endDateObj = firestoreTimestampToDate(endDate);

    // Create a new date object for payDate, add one day
    const payDateObj = new Date(endDateObj);
    payDateObj.setDate(payDateObj.getDate() + 1);

    const body = JSON.stringify({
      firstName: listingLenderDetails.firstName,
      lenderEmail: listingLenderDetails.email,
      renterUsername: userDoc.username,
      listingTitle,
      grossEarningsCents: calculateTotalCostCents(numberOfDays, listingDetails),
      netEarningsCents:
        calculateTotalCostCents(numberOfDays, listingDetails) -
        guaranteePackage.feeCents,
      duration: numberOfDays,
      startDate: startDateObj.toLocaleDateString(),
      endDate: endDateObj.toLocaleDateString(),
      payDate: payDateObj.toLocaleDateString(), // Add this new field
      guaranteePackage,
    });

    try {
      const response = await fetch(
        `https://rentalconfirmedlenderemail-rentalconfirmedlendere-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );
      const data = await response.json();
    } catch (error) {
      toast.error("Failed to send confirmation email to lender.", {
        autoClose: 3000,
      });
    }
  };

  const writePaymentIntentToFirebase = async (
    paymentIntent,
    bookingId,
    rentalDocId
  ) => {
    const rentalDocumentRef = doc(
      db,
      "users",
      authUser.uid,
      "rentals",
      rentalDocId
    );
    const lenderDocumentRef = doc(
      db,
      "users",
      lenderUid,
      "bookings",
      bookingId
    );
    const rentalRequestRef = doc(db, "rental-requests", rentalRequestChatId);

    await Promise.all([
      updateDoc(rentalDocumentRef, { paymentIntent }),
      updateDoc(lenderDocumentRef, { paymentIntent }),
      updateDoc(rentalRequestRef, { paymentIntent }),
    ]);
  };

  const confirmPayment = async (clientSecret) => {
    if (!stripe || !elements) {
      return null;
    }
    setPaymentStatusMessage("");
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/rental-success`,
      },
      redirect: "if_required",
    });

    if (error) {
      setPaymentStatusMessage(error.message);
      throw new Error(error.message);
    } else if (paymentIntent) {
      return paymentIntent;
    }
    return null;
  };

  const handleConfirmRental = async (event) => {
    event.preventDefault();
    if (isLoading) return;
    setIsLoading(true);

    if (!termsChecked) {
      toast.error(
        "You need to select 'I have reviewed and agree to the KitShare Terms and Conditions' to check out.",
        { autoClose: 5000 }
      );
      setIsLoading(false);
      return;
    }

    try {
      const existingBookings = listingDetails.bookings || [];
      if (!isBookingAvailable(existingBookings, startDate, endDate)) {
        setIsLoading(false);
        return;
      }

      const bookingId = uuidv4();

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setPaymentStatusMessage(submitError.message);
        setIsLoading(false);
        return;
      }

      //      const paymentIntent = await confirmPayment();
      const clientSecret = await createPaymentIntent();

      if (!clientSecret) throw new Error("Payment confirmation failed.");

      const paymentIntent = await confirmPayment(clientSecret);

      // Create rental document for renter
      const rentalDocId = await createDocumentForUser(authUser.uid, "rentals", {
        bookingId,
        listingId,
        lenderUid,
        numberOfDays,
        startDate,
        endDate,
        rentalCostCents,
        rateCents,
        totalCostCents,
        serviceFeeCents,
        createdAt: serverTimestamp(),
      });

      // Increment rentalCount for renter
      //await incrementCounterForUser(currentUser.uid, "rentalCount");

      await Promise.all([
        incrementCounterForUser(authUser.uid, "rentalCount"),
        incrementCounterForUser(lenderUid, "bookingCount"),
      ]);

      // Create booking document for lender
      // Earnings calculated based upon the selected guarantee package the lender has selected
      const firebaseBookingId = await createDocumentForUser(
        lenderUid,
        "bookings",
        {
          bookingId,
          listingId,
          renterUid: authUser.uid,
          numberOfDays,
          guaranteePackage,
          rateCents,
          startDate,
          endDate,
          //earningsCents: rateCents * numberOfDays - guaranteePackage.feeCents,
          grossEarningsCents: calculateTotalCostCents(
            numberOfDays,
            listingDetails
          ),
          netEarningsCents:
            calculateTotalCostCents(numberOfDays, listingDetails) -
            guaranteePackage.feeCents,
          createdAt: serverTimestamp(),
        }
      );

      //await incrementCounterForUser(lenderUid, "bookingCount");

      await createRentalBookingInListingDocument(bookingId);

      await updateRentalRequestStatus();

      await writePaymentIntentToFirebase(
        paymentIntent,
        firebaseBookingId,
        rentalDocId
      );

      await Promise.all([
        sendRentalConfirmedEmailToLender(),
        sendRentalConfirmedEmailToRenter(),
      ]);

      toast.success("Rental confirmed.", { autoClose: 3000 });
      setIsLoading(false);

      // navigate to rental-confirmed page with rental information
      navigate("/rental-confirmed", {
        state: {
          email: authUser.email,
          firstName: userDoc.firstName,
          listingId,
          listingTitle,
          startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
          endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
          duration: numberOfDays,
          rentalCostCents,
          serviceFeeCents,
          totalCostCents,
        },
      });
    } catch (error) {
      console.error(error);
      toast.error(`Failed to confirm rental: ${error.message}`, {
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleConfirmRental}>
      <div className="flex flex-col">
        <div className="bg-gray-100 py-2 px-1 rounded-md shadow-md my-3">
          <PaymentElement />
        </div>

        {paymentStatusMessage && (
          <div className="bg-gray-200 rounded-md p-2 font-semibold text-center my-2">
            {paymentStatusMessage}
          </div>
        )}

        {/* Terms and Conditions */}
        <div className="my-2 flex align-center justify-center">
          <input
            type="checkbox"
            id="terms"
            checked={termsChecked}
            onChange={(e) => setTermsChecked(e.target.checked)}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="terms" className="ml-2 text-xs md:text-sm">
            I have reviewed and agree to the{" "}
            <a href="/terms-and-conditions" className="text-blue-500 underline">
              KitShare Terms and Conditions
            </a>
          </label>
        </div>

        <div className="flex justify-center my-2">
          <button
            className={`min-w-48 px-3 ${
              termsChecked ? "btn-continue" : "btn-secondary"
            }`}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <BeatLoader color="white" />
            ) : (
              <div className="text-sm md:text-base">
                Confirm Rental €{formatCurrency(totalCostCents)}
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="flex justify-center align-center items-center mt-1">
        <p className="text-xs md:text-sm text-center font-bold text-gray-700">
          Payment secured with
        </p>
        <img src={stripeLogo} alt="stripeLogo" className="inline w-1/6"></img>
      </div>
    </form>
  );
};

export default CheckoutForm;
