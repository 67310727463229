import React, { useEffect, useState } from "react";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { BeatLoader } from "react-spinners";
import formatCurrency from "../utils/formatCurrency";

// Custom Option component for React Select
const Option = (props) => {
  const { data, isDisabled } = props;
  const booking = data.booking;

  return (
    <components.Option {...props} isDisabled={isDisabled}>
      <div className={`flex items-center ${isDisabled ? "opacity-50" : ""}`}>
        {booking.listingImage ? (
          <img
            src={booking.listingImage}
            alt={booking.listingTitle}
            className="w-12 h-12 object-cover rounded-md mr-3"
          />
        ) : (
          <div className="w-12 h-12 bg-gray-200 rounded-md mr-3 flex items-center justify-center">
            <span className="text-gray-500">No Image</span>
          </div>
        )}
        <div>
          <p className="text-sm font-medium">{booking.listingTitle}</p>
          <p className="text-xs text-gray-500">
            Rented by {booking.renterUsername} ({booking.startDateFormatted} -{" "}
            {booking.endDateFormatted})
          </p>
          <p className="text-xs">
            Guarantee:{" "}
            {booking.guaranteePackage.name
              ? booking.guaranteePackage.name
              : "None"}
          </p>
        </div>
      </div>
    </components.Option>
  );
};

// Custom SingleValue component for React Select
const SingleValue = (props) => {
  const { data } = props;
  const booking = data.booking;

  return (
    <components.SingleValue {...props}>
      <div className="flex items-center">
        {booking.listingImage ? (
          <img
            src={booking.listingImage}
            alt={booking.listingTitle}
            className="w-8 h-8 object-cover rounded-md mr-2"
          />
        ) : (
          <div className="w-8 h-8 bg-gray-200 rounded-md mr-2 flex items-center justify-center">
            <span className="text-gray-500 text-xs">No Image</span>
          </div>
        )}
        <div>
          <p className="text-sm font-semibold">
            {booking.listingTitle}
            {booking.isOutsideClaimWindow && (
              <span className="text-xs text-red-500 ml-2">(Unavailable)</span>
            )}
          </p>
          <div className="flex flex-col gap-1">
            <p className="text-xs text-gray-500 mt-1">
              {booking.startDateFormatted} - {booking.endDateFormatted}
            </p>
            <p className="text-xs text-gray-500">
              <span className="font-semibold">Rented by:</span>{" "}
              {booking.renterUsername}
            </p>
            <p className="text-xs text-gray-500">
              <span className="font-semibold">Guarantee Package:</span>{" "}
              {booking.guaranteePackage.name}
            </p>
          </div>
        </div>
      </div>
    </components.SingleValue>
  );
};

const Claim = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [issueDescription, setIssueDescription] = useState("");
  const [requestedAmount, setRequestedAmount] = useState("");
  const [loading, setLoading] = useState(true);
  const [submittingClaim, setSubmittingClaim] = useState(false);
  const [maxClaimCents, setMaxClaimCents] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  // Cloudinary upload states
  const [imageFiles, setImageFiles] = useState([]); // Stores the file info
  const [imageURLs, setImageURLs] = useState([]); // Stores the secure URLs

  const navigate = useNavigate();
  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  useEffect(() => {
    if (!authUser) {
      toast.error("You need to be logged in to submit a claim.", {
        autoClose: 4000,
      });
      navigate("/");
      return;
    }

    const fetchBookings = async () => {
      try {
        const userUid = authUser.uid;
        const bookingsRef = collection(db, "users", userUid, "bookings");
        const bookingsSnapshot = await getDocs(bookingsRef);

        const now = new Date();
        const bookingsData = [];

        for (const bookingDoc of bookingsSnapshot.docs) {
          const bookingData = bookingDoc.data();
          const listingId = bookingData.listingId;

          // Fetch listing details
          const listingDocRef = doc(db, "listings", listingId);
          const listingDocSnap = await getDoc(listingDocRef);
          const listingData = listingDocSnap.exists()
            ? listingDocSnap.data()
            : {};

          const listingTitle = listingData.title || "Unknown Listing";
          const listingImage = listingData.imageURLs
            ? listingData.imageURLs[0]
            : null;

          // Fetch renter username
          const renterUid = bookingData.renterUid;
          const renterDocRef = doc(db, "users", renterUid);
          const renterDocSnap = await getDoc(renterDocRef);
          const renterUsername = renterDocSnap.exists()
            ? renterDocSnap.data().username
            : "Unknown User";

          // Convert Firestore timestamps to JS Date objects
          const rawStartDate = bookingData.startDate
            ? firestoreTimestampToDate(bookingData.startDate)
            : null;
          const rawEndDate = bookingData.endDate
            ? firestoreTimestampToDate(bookingData.endDate)
            : null;

          // Also prepare formatted strings for display
          const startDateFormatted = rawStartDate
            ? rawStartDate.toLocaleDateString()
            : "Unknown Date";
          const endDateFormatted = rawEndDate
            ? rawEndDate.toLocaleDateString()
            : "Unknown Date";

          // 1) We do NOT allow claims for future rentals => current date < end date => not completed.
          // 2) We only allow claims if the end date was within the LAST 7 days => differenceInDays <= 7
          // i.e. 0 <= differenceInDays <= 7
          // If differenceInDays < 0 => future
          // If differenceInDays > 7 => outside claim window
          let isOutsideClaimWindow = true;
          if (rawEndDate) {
            const differenceInTime = now - rawEndDate;
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);

            // Must be >= 0 (the rental has ended) and <= 7 days
            if (differenceInDays >= 0 && differenceInDays <= 7) {
              isOutsideClaimWindow = false;
            }
          }

          // Guarantee package
          const guaranteePackage = bookingData.guaranteePackage || {
            name: "None",
          };

          bookingsData.push({
            id: bookingDoc.id,
            listingTitle,
            listingImage,
            renterUsername,

            // The real Date objects
            rawStartDate,
            rawEndDate,

            // The display strings
            startDateFormatted,
            endDateFormatted,

            // If we are outside the 7 day claim window or the rental hasn't ended => disabled
            isOutsideClaimWindow,

            guaranteePackage,
            ...bookingData,
          });
        }

        setBookings(bookingsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
        toast.error("Failed to load bookings.");
        setLoading(false);
      }
    };

    fetchBookings();
  }, [authUser, navigate]);

  useEffect(() => {
    loadCloudinaryScript(() => {});
  }, []);

  const loadCloudinaryScript = (callback) => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };

  // Cloudinary upload widget code
  const cloudName = "prodCloudinary";
  const uploadPreset = "kitShareUploadPreset";

  // Open Cloudinary Upload Widget
  const openUploadWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName,
          uploadPreset,
          multiple: true,
          maxFiles: 5 - imageFiles.length,
          sources: ["local", "camera"],
          folder: "guaranteeClaims",
          context: {
            alt: "user_uploaded_image",
            caption: "Uploaded on KitShare",
          },
          resourceType: "auto",
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            console.log(result);
            setImageFiles((prevFiles) => [...prevFiles, result.info]);
            setImageURLs((prevUrls) => [...prevUrls, result.info.secure_url]);
            console.log("File uploaded successfully:", result.info.secure_url);
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload file. Please try again.");
          }
        }
      )
      .open();
  };

  // Function to remove an uploaded image
  const removeImage = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setImageURLs((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleBookingChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedBooking(selectedOption.booking);
    setMaxClaimCents(selectedOption.booking.guaranteePackage?.maxCoverageCents);
  };

  // Increment claim count
  const incrementClaimCount = async () => {
    const userRef = doc(db, "users", authUser.uid);
    await updateDoc(userRef, {
      claimCount: increment(1),
    });
  };

  // Send guarantee email to support@kitshare.ie and inform the guarantee-claims Slack channel
  const sendGuaranteeEmailAndSlackMessage = async (booking, claimDocId) => {
    const {
      id,
      listingId,
      listingTitle,
      startDate,
      endDate,
      guaranteePackage,
      renterUsername,
      renterUid,
    } = booking;

    // We'll rely on the original booking fields for start & end date
    // if you want the formatted strings, use booking.startDateFormatted, etc.
    const body = JSON.stringify({
      username: userDoc.username,
      uid: authUser.uid,
      bookingId: id,
      listingId,
      listingTitle: listingTitle,
      startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
      endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
      guaranteePackage,
      renterUsername,
      renterUid,
      issueDescription,
      requestedAmountCents: requestedAmount * 100,
      imageURLs,
      claimDocId,
    });

    try {
      const response = await fetch(
        `https://claimemail-claimemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );
      const data = await response.json();
      console.log("Guarantee email/Slack message response:", data);
    } catch (error) {
      toast.error(
        "Failed to send email to KitShare, please contact us using other methods.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmittingClaim(true);

    // Perform validation
    if (!selectedBooking) {
      toast.error("Please select a booking.");
      setSubmittingClaim(false);
      return;
    }
    if (!issueDescription.trim()) {
      toast.error("Please describe the issue.");
      setSubmittingClaim(false);
      return;
    }
    if (!requestedAmount || isNaN(requestedAmount) || requestedAmount <= 0) {
      toast.error("Please enter a valid requested amount.");
      setSubmittingClaim(false);
      return;
    }
    if (imageFiles.length === 0) {
      toast.error("Please upload the required documents.");
      setSubmittingClaim(false);
      return;
    }

    try {
      // Check if user has already submitted a claim for this booking
      const claimsRef = collection(db, "claims");
      const claimsSnapshot = await getDocs(claimsRef);
      const existingClaim = claimsSnapshot.docs.find(
        (doc) =>
          doc.data().userUid === authUser.uid &&
          doc.data().bookingId === selectedBooking.id
      );
      if (existingClaim) {
        toast.error(
          "You've already submitted a claim for this booking. Please wait for a response."
        );
        setSubmittingClaim(false);
        return;
      }

      // Prepare claim data
      const claimData = {
        userUid: authUser.uid,
        bookingId: selectedBooking.id,
        issueDescription,
        requestedAmountCents: parseFloat(requestedAmount * 100),
        uploadedFiles: imageURLs,
        createdAt: new Date(),
        status: "Pending",
      };

      console.log("Submitting claim data:", claimData);

      // Save the claim to Firestore
      const claimDoc = await addDoc(collection(db, "claims"), claimData);

      // Send automated email to KitShare support + Slack
      await sendGuaranteeEmailAndSlackMessage(selectedBooking, claimDoc.id);

      // Increment this user's claim count
      await incrementClaimCount();

      toast.success(
        "Your claim has been submitted. KitShare aims to respond to all claims within 7 days."
      );
      setSubmittingClaim(false);
      //navigate("/");
    } catch (error) {
      console.error("Error submitting claim:", error);
      toast.error("Failed to submit your claim. Please try again.");
      setSubmittingClaim(false);
    }
  };

  // Prepare options for React Select
  const bookingOptions = bookings.map((booking) => {
    const guaranteePackageName = booking.guaranteePackage?.name || "None";
    const isDisabledGuarantee = !["Bronze", "Silver", "Gold"].includes(
      guaranteePackageName
    );

    // If the booking is outside the 7-day claim window or there's no valid guarantee
    const isDisabled = isDisabledGuarantee || booking.isOutsideClaimWindow;

    // Label modifications
    const labelBase = booking.listingTitle;
    let labelSuffix = "";
    if (isDisabledGuarantee) {
      labelSuffix += labelSuffix ? ", no coverage" : " (No coverage)";
    }
    if (booking.isOutsideClaimWindow) {
      labelSuffix += labelSuffix ? ", outside window" : " (Outside window)";
    }

    return {
      value: booking.id,
      label: labelSuffix ? `${labelBase} ${labelSuffix}` : labelBase,
      booking: booking,
      isDisabled,
    };
  });

  return (
    <div className="max-w-3xl mx-auto px-2 md:px-3 md:pt-2 mt-5 bg-white shadow-md rounded-lg">
      {/* Header */}
      <h1 className="text-xl mb-3 header__center font-semibold">
        Submit a Claim
      </h1>
      <p className="text-sm md:text-base text-gray-700 mb-3 md:mb-8 text-center">
        We understand that unfortunate situations can occur. At KitShare, we're
        committed to supporting our community. If you've purchased a guarantee
        package and need to make a claim, please provide the details below, and
        we'll do our best to assist you.
      </p>

      {/* Claim Form */}
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Booking Selector */}
        <div>
          <label
            htmlFor="booking"
            className="text-sm font-semibold mb-2 text-gray-700"
          >
            Select Booking
          </label>
          {loading ? (
            <p>Loading bookings...</p>
          ) : bookings.length > 0 ? (
            <Select
              options={bookingOptions}
              components={{ Option, SingleValue }}
              value={selectedOption}
              onChange={handleBookingChange}
              isOptionDisabled={(option) => option.isDisabled}
              placeholder="Choose a booking"
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: "4px",
                  borderColor: "#cbd5e0",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#a0aec0",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#e6fffa"
                    : state.isFocused
                    ? "#f0fff4"
                    : null,
                  color: state.isDisabled ? "#a0aec0" : "#2d3748",
                  cursor: state.isDisabled ? "not-allowed" : "default",
                }),
              }}
            />
          ) : (
            <p>No bookings found.</p>
          )}
        </div>

        {/* Issue Description */}
        <div>
          <p className="text-sm font-semibold mb-1 text-gray-700">
            Describe the Issue
          </p>
          <textarea
            id="issueDescription"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            rows="4"
            className="text-sm md:text-base block w-full p-2 border border-gray-300 rounded-md outline-none focus:border-teal-600 focus:border-2"
            placeholder="Please provide details about the issue that occurred."
          />
        </div>

        {/* Requested Amount */}
        <div>
          <div className="flex align-center items-center gap-2">
            <p className="text-sm font-semibold text-gray-700">
              Requested Amount (€)
            </p>
            {maxClaimCents ? (
              <p className="text-sm font-semibold">
                (Max claim: €{formatCurrency(maxClaimCents)})
              </p>
            ) : null}
          </div>
          <input
            type="number"
            id="requestedAmountCents"
            value={requestedAmount}
            onChange={(e) => setRequestedAmount(e.target.value)}
            className="text-sm md:text-base mt-1 block w-full p-2 border border-gray-300 rounded-md outline-none focus:border-teal-600 focus:border-2"
            placeholder="Enter the amount you're requesting"
            min="0"
            max={maxClaimCents / 100}
            step="0.01"
          />
        </div>

        {/* File Uploads */}
        <div>
          <label className="block text-sm font-semibold mb-2 text-gray-700">
            Upload Required Documents
          </label>
          {/* Additional Information */}
          <ul className="list-disc list-inside text-sm text-gray-700 mb-4">
            <li>
              <strong>Photos/Videos:</strong> Time-stamped photos or videos of
              the item before and after the rental period, showing the condition
              of the item.
            </li>
            <li>
              <strong>Police Report:</strong> In cases of theft, a police report
              must be filed and submitted.
            </li>
            <li>
              <strong>Repair Estimate:</strong> For damages, an estimate from a
              professional repair service.
            </li>
          </ul>

          <div className="flex justify-center md:justify-start">
            <button
              type="button"
              onClick={openUploadWidget}
              className={`btn-continue px-5 ${
                !authUser || !selectedBooking
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={!authUser || !selectedBooking}
            >
              Upload Documents
            </button>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            You can upload up to 5 files. Accepted formats: images, videos,
            PDFs.
          </p>
          {imageFiles.length > 0 && (
            <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
              {imageFiles.map((imageFile, index) => (
                <div key={index} className="relative">
                  {/* <img
                    src={url}
                    alt={`Uploaded file ${index + 1}`}
                    className="w-full h-32 object-cover rounded-md"
                  /> */}
                  {imageFile.format === "jpg" ||
                  imageFile.format === "jpeg" ||
                  imageFile.format === "png" ? (
                    <img
                      src={imageFile.secure_url}
                      alt={`Uploaded file ${index + 1}`}
                      className="w-full h-32 object-cover rounded-md"
                    />
                  ) : (
                    <div className="w-full flex justify-center items-center h-32 bg-gray-200 rounded-md">
                      <p className="text-sm px-5">
                        {imageFile.original_filename}
                      </p>
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full px-2 py-1 hover:font-bold"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="w-full flex justify-end">
          <button
            type="submit"
            className={
              parseInt(requestedAmount * 100) > maxClaimCents ||
              !requestedAmount
                ? "btn-secondary px-8 cursor-not-allowed"
                : "btn-continue px-8 cursor-pointer"
            }
            disabled={
              parseInt(requestedAmount * 100) > maxClaimCents ||
              submittingClaim === true ||
              !requestedAmount
            }
          >
            {submittingClaim ? <BeatLoader color="white" /> : "Submit Claim"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Claim;
