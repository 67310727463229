import React from "react";
import { useNavigate } from "react-router-dom";
import savings from "../assets/savings.png";

const BecomeLenderCTA = ({ authUser }) => {
  const navigate = useNavigate();

  return (
    <div className="relative p-6 md:p-5 rounded-lg shadow-sm flex flex-col md:flex-row items-center justify-between gap-8 md:gap-10 overflow-hidden">
      {/* Left Section with Gradient Background */}
      <div className="relative w-full md:w-2/3 p-8 md:p-16 text-white flex flex-col justify-center bg-gradient-to-r from-teal-700 to-teal-500 rounded-lg md:rounded-l-lg md:rounded-r-none">
        <h2 className="text-2xl md:text-4xl font-semibold mb-4">
          Earn Cash by Listing Your Items!
        </h2>
        <p className="text-base md:text-lg mb-6">
          Got items lying around that others could use? Turn them into a source
          of extra income by listing them on our platform. It's easy, secure,
          and you could start earning today!
        </p>
        <div className="flex w-full justify-center">
          <button
            className="bg-white text-sm text-teal-600 font-semibold py-3 px-6 rounded-lg hover:font-bold hover:bg-gray-100 transition ease-in-out duration-300 md:w-1/2"
            onClick={() =>
              navigate(`${authUser ? "/new-listing" : "/sign-up"}`)
            }
          >
            {authUser ? "List an Item Today" : "Become a Lender Today"}
          </button>
        </div>
      </div>

      {/* Slanted Divider */}
      <div className="hidden md:block absolute top-0 bottom-0 left-2/3 w-[3%] transform -translate-x-1/2 rotate-12 bg-white"></div>

      {/* Right Section with White Background */}
      <div className="w-full md:w-1/3 flex items-center justify-center bg-white p-4 md:p-8 rounded-lg md:rounded-r-lg md:rounded-l-none">
        <img src={savings} alt="savings" className="w-72 md:w-96" />
      </div>
    </div>
  );
};

export default BecomeLenderCTA;
