import React, { useState, useEffect } from "react";
import { doc, serverTimestamp, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import LenderGuaranteeModal from "./LenderGuaranteeModal";
import LenderVerificationModal from "../LenderVerificationModal";
import formatCurrency from "../../utils/formatCurrency";

const AvailabilityRequestLender = ({
  message,
  onOpenModal,
  recipientDetails,
  rentalRequestChatId,
  authUser,
  userDoc,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  //const { currentUser } = useAuth();

  // Destructure rental request message details
  const {
    listingTitle,
    startDate,
    content,
    endDate,
    totalCostCents,
    rateCents,
    numberOfDays,
    listingImage,
    serviceFeeCents,
    rentalCostCents,
    renterUid,
    depositCents = null,
  } = message;

  // Extract some info for the email
  const { email, firstName } = recipientDetails;

  const formattedStartDate =
    firestoreTimestampToDate(startDate).toLocaleDateString();
  const formattedEndDate =
    firestoreTimestampToDate(endDate).toLocaleDateString();

  // --------------------------------------------------
  // 2) This function sends availability confirmation email
  // --------------------------------------------------
  const sendAvailabilityConfirmedEmail = async () => {
    try {
      const body = JSON.stringify({
        firstName,
        email,
        lenderUsername: userDoc.username,
        listingTitle,
        numberOfDays,
        startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
        endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
        rateCents,
        serviceFeeCents,
        totalCostCents,
      });

      setIsLoading(true);
      const response = await fetch(
        `https://availabilityconfirmedemail-availabilityconfirmede-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body,
        }
      );

      await response.json(); // or check response status
      setIsLoading(false);
      console.log("Email sent successfully");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Failed to send confirmation email.");
    }
  };

  // --------------------------------------------------
  // 3) Decide whether to open verification or guarantee checklist
  // --------------------------------------------------
  const showVerificationOrChecklistModal = () => {
    // We used to check `currentUser.verificationStatus`.
    // Now we check `currentUser.veriff?.decision`.
    // If it's not "approved", or Stripe bits are incomplete -> open verification.
    if (
      userDoc?.veriff?.decision !== "approved" || // <-- CHANGED
      !userDoc.stripeAccountId ||
      !userDoc.stripeOnboardingCompleted
    ) {
      setIsVerificationModalOpen(true);
    } else {
      setIsChecklistModalOpen(true);
    }
  };

  // --------------------------------------------------
  // 4) Confirm availability => set guarantee package, update Firestore, send email
  // --------------------------------------------------
  const handleConfirmAvailability = async () => {
    if (!selectedPackage) {
      toast.error("Please select a guarantee package.");
      return;
    }

    try {
      setIsLoading(true);

      // 4a) Update the message doc
      const messageRef = doc(
        db,
        "rental-requests",
        rentalRequestChatId,
        "messages",
        message.id
      );
      await updateDoc(messageRef, {
        type: "availability-confirmed",
        guaranteePackage: selectedPackage,
        createdAt: serverTimestamp(),
      });

      // 4b) Update the main rental-requests doc
      const rentalRequestRef = doc(db, "rental-requests", rentalRequestChatId);
      await updateDoc(rentalRequestRef, {
        guaranteePackage: selectedPackage,
        status: "availability-confirmed",
        unreadBy: [renterUid],
      });

      // 4c) Send email
      await sendAvailabilityConfirmedEmail();

      setIsLoading(false);
      toast.success("Requestor has been notified of availability.", {
        autoClose: 3000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Failed to confirm availability.");
    }
  };

  // Format timestamps
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  return (
    <div>
      {/* Verification modal */}
      {isVerificationModalOpen && (
        <LenderVerificationModal
          isOpen={isVerificationModalOpen}
          onClose={() => setIsVerificationModalOpen(false)}
          authUser={authUser}
          userDoc={userDoc}
        />
      )}

      {/* Guarantee selection modal */}
      <LenderGuaranteeModal
        isOpen={isChecklistModalOpen}
        onClose={() => setIsChecklistModalOpen(false)}
        handleConfirm={handleConfirmAvailability}
        rentalRequest={message}
        setSelectedPackage={setSelectedPackage}
      />

      <h2 className="text-center font-semibold">Availability Request</h2>
      <p className="text-center my-5 new-line text-sm md:text-base">
        {content}
      </p>

      {/* Listing preview */}
      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md" />
        <p className="font-semibold mt-2 text-sm md:text-base">
          {listingTitle}
        </p>
      </div>

      {/* Basic info */}
      <div className="flex flex-col items-center gap-2">
        <p className="text-sm md:text-base">
          <span className="font-bold">Your earnings:</span> €
          {formatCurrency(rentalCostCents * 0.9)}
        </p>
        <p className="text-sm md:text-base">
          <span className="font-semibold">Duration:</span> {numberOfDays}{" "}
          {numberOfDays === 1 ? "day" : "days"}
        </p>
        <p className="text-sm md:text-base text-center">{`${
          formattedStartDate === formattedEndDate
            ? formattedStartDate
            : `${formattedStartDate} - ${formattedEndDate}`
        }`}</p>
        {depositCents && (
          <p className="text-sm md:text-base">
            <span className="font-bold">Required deposit:</span> €
            {formatCurrency(depositCents)}
          </p>
        )}
      </div>

      {/* Action buttons */}
      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button
          className="p-2 btn-confirm"
          onClick={showVerificationOrChecklistModal}
          disabled={isLoading}
        >
          {isLoading ? (
            <BeatLoader />
          ) : (
            <p className="text-sm md:text-base">
              <FontAwesomeIcon icon={faCheck} /> Yes, it's available
            </p>
          )}
        </button>
        <button
          className="text-sm md:text-base py-2 px-5 btn-delete"
          onClick={onOpenModal}
        >
          <FontAwesomeIcon icon={faX} /> Reject Request
        </button>
      </div>

      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>
      </div>
    </div>
  );
};

export default AvailabilityRequestLender;
