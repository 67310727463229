import React, { useEffect, useState } from "react";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUserDoc } from "../hooks/useUserDoc";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase.config";
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import CategorySelector from "../components/CategorySelector";

const EditListing = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [isConfirmDeleteListingModalOpen, setIsConfirmDeleteListingModalOpen] =
    useState(false);
  const [imageURLs, setImageURLs] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    dailyRate: "",
    threeDayCost: "",
    sevenDayCost: "",
    deposit: "",
    quantity: "",
    minRentDays: "",
    location: "",
    isActive: "",
  });

  const {
    title,
    description,
    dailyRate,
    threeDayCost,
    sevenDayCost,
    deposit,
    isActive,
  } = formData;

  const { authUser } = useAuthUser();
  const { userDoc } = useUserDoc();

  const { listingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser) {
      fetchListingData();
    } else {
      toast.error("You need to be logged in to edit a listing.", {
        autoClose: 5000,
      });
      navigate("/");
    }
  }, [authUser, listingId, navigate]);

  const fetchListingData = async () => {
    setShowSpinner(true);
    try {
      const docRef = doc(db, "listings", listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();

        // Check if the current user is the lender of the listing
        if (authUser.uid !== data.lenderUid) {
          toast.error("You need to be the lender of this listing to edit.", {
            autoClose: 5000,
          });
          navigate("/");
          return;
        }

        // Convert amounts from cents to euros for form display
        setFormData({
          title: data.title,
          description: data.description,
          dailyRate: (data.dailyRateCents / 100).toFixed(2),
          threeDayCost: data.threeDayCostCents
            ? (data.threeDayCostCents / 100).toFixed(2)
            : "",
          sevenDayCost: data.sevenDayCostCents
            ? (data.sevenDayCostCents / 100).toFixed(2)
            : "",
          deposit: data.depositCents
            ? (data.depositCents / 100).toFixed(2)
            : "",
          quantity: data.quantity || "",
          minRentDays: data.minRentDays || "",
          location: data.location || "",
          isActive: data.isActive || "",
          category: data.category || "",
          subcategory: data.subcategory || "",
        });

        setImageURLs(data.imageURLs || []);

        // Set selected category and subcategory
        setSelectedCategory({ value: data.category, label: data.category });
        if (data.subcategory) {
          setSelectedSubcategory({
            value: data.subcategory,
            label: data.subcategory,
          });
        }
      } else {
        toast.error("Listing not found");
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to fetch listing data: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const loadCloudinaryScript = () => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        console.log("Cloudinary script loaded");
      };
    }
  };

  useEffect(() => {
    loadCloudinaryScript();
  }, []);

  const openUploadWidget = () => {
    setShowSpinner(true);
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: "prodcloudinary",
          uploadPreset: "kitShareUploadPreset",
          multiple: true,
          maxFiles: 5,
          sources: ["local", "camera"],
          folder: "listingImages",
          context: {
            alt: "user_uploaded_image",
            caption: "Uploaded on KitShare",
          },
          resourceType: "image",
        },
        (error, result) => {
          setShowSpinner(false);
          if (!error && result && result.event === "success") {
            setImageURLs((prevUrls) => [...prevUrls, result.info.secure_url]);
            console.log("Image uploaded successfully:", result.info.secure_url);
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload image. Please try again.");
          }
        }
      )
      .open();
  };

  const handleCategorySelected = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setFormData((prev) => ({ ...prev, category: category.value }));
  };

  const handleSubcategorySelected = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setFormData((prev) => ({ ...prev, subcategory: subcategory.value }));
  };

  const handleRemoveImage = (url) => {
    setImageURLs((prev) => prev.filter((image) => image !== url));
  };

  const ratesAreCorrect = (
    dailyRateCents,
    threeDayCostCents,
    sevenDayCostCents
  ) => {
    const daily = dailyRateCents;
    const threeDay = threeDayCostCents;
    const sevenDay = sevenDayCostCents;

    if (isNaN(daily)) {
      return false;
    }

    if (threeDay && sevenDay) {
      return (
        threeDay <= daily * 3 && sevenDay <= daily * 7 && threeDay <= sevenDay
      );
    } else if (threeDay) {
      return threeDay <= daily * 3;
    } else if (sevenDay) {
      return sevenDay <= daily * 7;
    } else {
      // Only dailyRate is provided
      return true;
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    try {
      // Convert to cents
      const dailyRateCents = Math.round(parseFloat(dailyRate) * 100);
      const threeDayCostCents = threeDayCost
        ? Math.round(parseFloat(threeDayCost) * 100)
        : null;
      const sevenDayCostCents = sevenDayCost
        ? Math.round(parseFloat(sevenDayCost) * 100)
        : null;
      const depositCents = deposit
        ? Math.round(parseFloat(deposit) * 100)
        : null;

      // Validate rates
      if (
        !ratesAreCorrect(dailyRateCents, threeDayCostCents, sevenDayCostCents)
      ) {
        toast.error(
          "Something looks wrong with your rates. Your 3 day price should be larger than the daily rate. The 7 day price should be larger than the 3 day price.",
          { autoClose: 5000 }
        );
        setShowSpinner(false);
        return;
      }

      // Check if user has entered 0 for deposit - inform them to remove if so
      if (depositCents === 0) {
        toast.info(
          "Please remove 0 from optional deposit, leave this blank instead.",
          {
            autoClose: 5000,
          }
        );
        setShowSpinner(false);
        return;
      }

      // Build the updated data object
      const updatedData = {
        title,
        description,
        dailyRateCents,
        imageURLs,
        category: selectedCategory ? selectedCategory.value : formData.category,
        subcategory: selectedSubcategory
          ? selectedSubcategory.value
          : formData.subcategory,
      };

      // Handle deposit field
      if (depositCents && depositCents > 0) {
        updatedData.depositCents = depositCents;
      } else if (deposit === "" || deposit === null || isNaN(depositCents)) {
        // User has removed the deposit; delete the field from Firestore
        updatedData.depositCents = deleteField();
      }

      if (threeDayCostCents) {
        updatedData.threeDayCostCents = threeDayCostCents;
      }

      if (sevenDayCostCents) {
        updatedData.sevenDayCostCents = sevenDayCostCents;
      }

      const listingRef = doc(db, "listings", listingId);
      await updateDoc(listingRef, updatedData);

      toast.success("Listing updated successfully!");
      navigate(`/listings/${listingId}`);
    } catch (error) {
      console.error("Failed to update listing: ", error);
      toast.error("Error updating listing: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const changeListingStatus = async () => {
    setShowSpinner(true);
    try {
      const listingRef = doc(db, "listings", listingId);
      // Toggle isActive
      await updateDoc(listingRef, {
        isActive: !isActive,
      });

      // Show a success message based on the new value
      toast.success(
        `You have updated the listing to be ${
          isActive ? "inactive" : "active"
        }.`
      );

      // Update local state to reflect new isActive value
      setFormData((prev) => ({
        ...prev,
        isActive: !prev.isActive,
      }));
    } catch (error) {
      toast.error("Failed to change status: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };
  const onChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      if (value !== "") {
        value = parseFloat(value);
        if (isNaN(value)) value = "";
      } else {
        value = "";
      }
    }

    // Inform the user to remove deposit if they set it as 0
    if (e.target.id === "deposit" && value === 0) {
      toast.info("Leave this blank if no deposit is required.");
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  const handleDeleteListing = async () => {
    console.log("deleting listing");
  };

  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "24px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div className="force-footer">
      <div className="main-content bg-gray-50">
        <div className="flex w-full justify-center">
          <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
            Edit Listing
          </h1>
        </div>

        {/* Confirm Delete Listing Modal */}
        {/* <Modal
          isOpen={isConfirmDeleteListingModalOpen}
          onRequestClose={() => setIsConfirmDeleteListingModalOpen(false)}
          contentLabel="Delete Account"
          ariaHideApp={false}
          style={customStyles}
        >
          <div className="">
            <div className="flex justify-between ">
              <h2 className="text-lg md:text-xl font-semibold mb-4">
                Delete Listing
              </h2>
              <div
                className="hover:cursor-pointer hover:font-bold text-xl"
                onClick={() => setIsConfirmDeleteListingModalOpen(false)}
              >
                <FaX />
              </div>
            </div>
            <p className="mb-4 text-gray-700">
              Deleting this listing is irreversible. You will not be able to
              retrieve any listing details back once deleted.
            </p>

            <div className="mt-4 flex justify-end gap-3">
              <button
                onClick={() => setIsConfirmDeleteListingModalOpen(false)}
                className="btn-cancel px-5"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteListing}
                className="btn-continue px-5"
              >
                Delete Listing
              </button>
            </div>
          </div>
        </Modal> */}

        <div className="flex justify-center">
          <form
            onSubmit={handleSaveChanges}
            className="w-full md:w-2/3 xl:w-5/12 space-y-6"
          >
            {/* Listing Details Section */}
            <section className="bg-white p-4 rounded-md shadow-md">
              <h2 className="text-lg font-semibold mb-4">Listing Details</h2>
              <div className="flex flex-col mb-4">
                <label className="text-sm font-bold mb-1">Listing Title</label>
                <input
                  className="shadow-md outline-none focus:border-emerald-500 p-2 text-sm sm:text-base rounded-md border"
                  type="text"
                  id="title"
                  value={title}
                  onChange={onChange}
                  placeholder="Enter a descriptive title"
                  required
                />
              </div>

              {/* Category Selector */}
              <CategorySelector
                onCategorySelected={handleCategorySelected}
                onSubcategorySelected={handleSubcategorySelected}
                initialCategory={formData.category}
                initialSubcategory={formData.subcategory}
              />

              <div className="flex flex-col mt-4 mb-4">
                <label className="text-sm font-bold mb-1">
                  Listing Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={onChange}
                  className="border-2 p-2 shadow-md outline-none focus:border-emerald-500 rounded-md text-sm sm:text-base"
                  placeholder="Describe your item..."
                  required
                  rows={10}
                ></textarea>
              </div>

              <div className="flex flex-col mb-4 space-y-4">
                <h3 className="text-md font-semibold">Rental Price (€)</h3>
                <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-4">
                  <div className="w-full sm:w-1/3">
                    <label className="text-sm font-bold mb-1">Daily Rate</label>
                    <input
                      className="shadow-md outline-none focus:border-emerald-500 p-2 rounded-md border text-sm sm:text-base"
                      type="number"
                      min={1}
                      required
                      step="0.01"
                      id="dailyRate"
                      value={dailyRate}
                      onChange={onChange}
                    />
                  </div>
                  <div className="w-full sm:w-1/3">
                    <label className="text-sm font-bold mb-1">
                      3-Day Price (Optional)
                    </label>
                    <input
                      className="shadow-md outline-none focus:border-emerald-500 p-2 rounded-md border text-sm sm:text-base"
                      type="number"
                      step="0.01"
                      id="threeDayCost"
                      value={threeDayCost}
                      onChange={onChange}
                    />
                  </div>
                  <div className="w-full sm:w-1/3">
                    <label className="text-sm font-bold mb-1">
                      7-Day Price (Optional)
                    </label>
                    <input
                      className="shadow-md outline-none focus:border-emerald-500 p-2 rounded-md border text-sm sm:text-base"
                      type="number"
                      step="0.01"
                      id="sevenDayCost"
                      value={sevenDayCost}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col mb-4">
                <label className="text-sm font-bold mb-1">
                  Deposit (Optional)
                </label>
                <input
                  className="shadow-md outline-none focus:border-emerald-500 p-2 rounded-md border text-sm sm:text-base"
                  type="number"
                  min={1}
                  step="0.01"
                  id="deposit"
                  value={deposit}
                  onChange={onChange}
                />
                <p className="text-xs text-gray-600 mt-1">
                  If you do not require a deposit, leave this field blank.
                </p>
              </div>

              {/*  */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  className={`btn-continue px-5 py-2 ${
                    !title ||
                    !description ||
                    !dailyRate ||
                    imageURLs.length === 0
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={
                    showSpinner ||
                    !title ||
                    !description ||
                    !dailyRate ||
                    imageURLs.length === 0
                  }
                >
                  {showSpinner ? "Saving..." : "Save Changes"}
                </button>
              </div>
            </section>

            {/* Images Section */}
            <section className="bg-white p-4 rounded-md shadow-md">
              <h2 className="text-lg text-gray-700 font-semibold mb-4">
                Images
              </h2>
              {imageURLs.length > 0 && (
                <div className="flex flex-wrap gap-2 mb-4">
                  {imageURLs.map((url, index) => (
                    <div key={index} className="relative">
                      <img
                        src={url}
                        alt="Uploaded"
                        className="w-20 h-20 object-cover rounded-md shadow-md"
                      />
                      <button
                        type="button"
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 hover:bg-red-700"
                        onClick={() => handleRemoveImage(url)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {imageURLs.length < 5 && (
                <div className="flex flex-col justify-end sm:flex-row gap-3">
                  <button
                    type="button"
                    className="btn-secondary px-4 py-2"
                    onClick={openUploadWidget}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Upload Images
                  </button>
                </div>
              )}
            </section>

            {/* Actions Section */}
            {/* <section className="bg-white p-4 rounded-md shadow-md space-y-4">
              <h2 className="text-lg font-semibold text-gray-700">Actions</h2>
              <p className="text-sm text-gray-700">
                Save your changes or update your listing status.
              </p>
              <div className="flex flex-col justify-end sm:flex-row gap-3"> */}
            {/* <button
                  type="button"
                  onClick={changeListingStatus}
                  className={`${
                    isActive ? "btn-delete" : "btn-continue"
                  } px-5 py-2`}
                  disabled={showSpinner}
                >
                  {isActive ? "Make Inactive" : "Make Active"}
                </button> */}
            {/* <button
                  type="submit"
                  className={`btn-continue px-5 py-2 ${
                    !title ||
                    !description ||
                    !dailyRate ||
                    imageURLs.length === 0
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={
                    showSpinner ||
                    !title ||
                    !description ||
                    !dailyRate ||
                    imageURLs.length === 0
                  }
                >
                  {showSpinner ? "Saving..." : "Save Changes"}
                </button>
              </div>
            </section> */}

            {/* Danger Zone */}
            {/* <section className="bg-white p-4 rounded-md shadow-md mt-4">
              <h2 className="text-lg font-semibold text-red-600 mb-2">
                Danger Zone
              </h2>
              <p className="text-sm text-gray-700 mb-3">
                Deleting your listing is irreversible. Please proceed with
                caution.
              </p>
              <div className="flex flex-col justify-end sm:flex-row gap-3">
                <button
                  type="button"
                  className="btn-delete px-5 py-2"
                  onClick={() => setIsConfirmDeleteListingModalOpen(true)}
                >
                  Delete Listing
                </button>
              </div>
            </section> */}
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditListing;
