import React, { useEffect, useState } from "react";
import { useAuthUser } from "../hooks/useAuthUser";
import Spinner from "../components/Spinner";
import ListingCard from "../components/ListingCard"; // Import your ListingCard component
import { toast } from "react-toastify";
import { db } from "../firebase.config";
import { doc, getDoc } from "firebase/firestore";

const Favourites = () => {
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true);

  const { authUser } = useAuthUser();

  useEffect(() => {
    const fetchFavouriteListings = async () => {
      setLoading(true);
      try {
        const userRef = doc(db, "users", authUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists() && userSnap.data().favourites) {
          const favouritesList = userSnap.data().favourites;
          const listings = await Promise.all(
            favouritesList.map(async (listingId) => {
              const listingRef = doc(db, "listings", listingId);
              const docSnap = await getDoc(listingRef);
              return {
                listingDetails: { id: docSnap.id, ...docSnap.data() },
              };
            })
          );
          setFavourites(listings);
        } else {
          setFavourites([]);
        }
      } catch (error) {
        console.error("Failed to fetch favourite listings: ", error);
        toast.error("Failed to load favourites");
      }
      setLoading(false);
    };

    fetchFavouriteListings();
  }, [authUser.uid]);

  const removeFavourite = (listingId) => {
    setFavourites(
      favourites.filter((fav) => fav.listingDetails.id !== listingId)
    );
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="flex w-full justify-center">
        <h1 className="w-full md:w-2/3 text-center my-5 text-xl font-bold header__center">
          My Favourites
        </h1>
      </div>

      {favourites.length > 0 ? (
        <div className="flex justify-center w-full mt-5 px-2">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 mb-5">
            {favourites.map((favourite, index) => (
              <ListingCard
                key={index}
                listingDetails={favourite.listingDetails}
                onRemove={() => removeFavourite(favourite.listingDetails.id)}
              />
            ))}
          </div>
        </div>
      ) : (
        <p className="text-center text-lg font-semibold text-gray-700">
          No favourites yet
        </p>
      )}
    </div>
  );
};

export default Favourites;
