import React from "react";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";

const AvailabilityRequestRenter = ({ message, onOpenModal }) => {
  const {
    listingTitle,
    startDate,
    content,
    endDate,
    totalCostCents,
    numberOfDays,
    listingImage,
    depositCents = 0,
    createdAt,
  } = message;

  const formattedStartDate =
    firestoreTimestampToDate(startDate).toLocaleDateString();
  const formattedEndDate =
    firestoreTimestampToDate(endDate).toLocaleDateString();

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    const date = timestamp.toDate();

    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  return (
    <div>
      <h2 className="text-center font-bold">Availability Request</h2>
      <p className="text-center my-5 new-line text-sm md:text-base">
        {content}
      </p>
      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md" />
        <p className="font-bold mt-2 text-sm md:text-base">{listingTitle}</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="text-sm md:text-base">
          <span className="font-bold">Total cost:</span> €
          {(totalCostCents / 100).toFixed(2)}
        </p>
        <p className="text-sm md:text-base">
          <span className="font-semibold">Duration:</span> {numberOfDays}{" "}
          {numberOfDays === 1 ? "day" : "days"}
        </p>
        <p className="text-sm md:text-base text-center">{`${
          formattedStartDate === formattedEndDate
            ? formattedStartDate
            : `${formattedStartDate} - ${formattedEndDate}`
        }`}</p>
        {depositCents > 0 && (
          <p className="text-sm md:text-base">
            <span className="font-bold">Required deposit:</span> €
            {(depositCents / 100).toFixed(2)}
          </p>
        )}
      </div>
      <p className="text-xs sm:text-sm text-center my-4">
        Waiting for the lender to confirm availability of the item
      </p>
      <div className="flex justify-center mt-3 mb-2">
        <button
          onClick={onOpenModal}
          className="p-2 btn-delete text-sm md:text-base"
        >
          <FontAwesomeIcon icon={faCancel} /> Cancel Request
        </button>
      </div>
      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(createdAt)}
        </p>
      </div>
    </div>
  );
};

export default AvailabilityRequestRenter;
